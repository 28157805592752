interface Scripts {
    name: string;
    src: string;
}  
export const ScriptStore: Scripts[] = [
    {name: 'slick', src: 'assets/js/vendor/slick.min.js'},
    {name: 'main', src: 'assets/js/main.js'},
    {name: 'jquery', src: 'assets/js/vendor/jquery.js'},
    {name: 'modernizer', src: 'assets/js/vendor/modernizer.min.js'},
    {name: 'feather', src: 'assets/js/vendor/feather.min.js'},
    {name: 'bootstrap', src: 'assets/js/vendor/bootstrap.js'},
    {name: 'text-type', src: 'assets/js/vendor/text-type.js'},
    {name: 'wow', src: 'assets/js/vendor/wow.js'},
    {name: 'aos', src: 'assets/js/vendor/aos.js'},
    {name: 'particles', src: 'assets/js/vendor/particles.js'}
];