<!--page header section start-->
<section class="page-header ptb-120 text-white bg-gradient">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <h1 class="display-5 fw-bold">Career</h1>
                <p class="lead">Seamlessly actualize client-based users after out-of-the-box value. Globally embrace
                    strategic data through frictionless expertise.</p>
            </div>
        </div>
    </div>
</section>
<!--page header section end-->

<!--open jobs section start-->
<section id="open-positions" class="open-jobs ptb-120">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-4 col-md-12">
                <div class="section-heading">
                    <h4 class="h5 text-primary">Our Jobs</h4>
                    <h2>Current Available Positions at Quiety</h2>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <p>Phosfluorescently disintermediate revolutionary paradigms before enabled interfaces. Dynamically
                    transition skills vis-a-vis virtual customer service via impactful partnerships with technically
                    sound paradigms with cutting-edge initiatives. </p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12">
                <a href="career-single.html"
                    class="text-decoration-none mt-4 mt-xl-0 mt-lg-0 single-open-job p-5 bg-dark text-white d-block rounded-custom">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="job-time h-6 mb-2"><i class="far fa-briefcase me-2"></i> <strong>Remote - Full
                                Time</strong></span>
                        <span class="badge px-3 py-2 bg-custom-light rounded-pill small">Developer</span>
                    </div>
                    <h3 class="h5">Jr Frontend Developer</h3>
                    <ul class="job-info-list list-inline list-unstyled text-muted">
                        <li class="list-inline-item"><span class="far fa-house-building me-1"></span> Google</li>
                        <li class="list-inline-item"><span class="far fa-map-marker-alt me-1"></span> London, UK</li>
                        <li class="list-inline-item"><span class="far fa-wallet me-1"></span> $35k - $45k</li>
                    </ul>
                    <div class="btn btn-outline-light btn-sm d-inline-block mt-4">Apply Now</div>
                </a>
            </div>
            <div class="col-lg-6 col-md-12">
                <a href="career-single.html"
                    class="text-decoration-none mt-4 mt-xl-0 mt-lg-0 single-open-job p-5 bg-dark text-white d-block rounded-custom">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="job-time h-6 mb-2"><i class="far fa-briefcase me-2"></i> <strong>Remote - Full
                                Time</strong></span>
                        <span class="badge px-3 py-2 bg-custom-light rounded-pill small">Designer</span>
                    </div>
                    <h3 class="h5">UI/UX and Product Designer</h3>
                    <ul class="job-info-list list-inline list-unstyled text-muted">
                        <li class="list-inline-item"><span class="far fa-house-building me-1"></span> Figma</li>
                        <li class="list-inline-item"><span class="far fa-map-marker-alt me-1"></span> San Francissco
                        </li>
                        <li class="list-inline-item"><span class="far fa-wallet me-1"></span> $25k - $35k</li>
                    </ul>
                    <div class="btn btn-outline-light btn-sm d-inline-block mt-4">Apply Now</div>
                </a>
            </div>
            <div class="col-lg-6 col-md-12">
                <a href="career-single.html"
                    class="text-decoration-none mt-4 single-open-job p-5 bg-dark text-white d-block rounded-custom">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="job-time h-6 mb-2"><i class="far fa-briefcase me-2"></i> <strong>Full
                                Time</strong></span>
                        <span class="badge px-3 py-2 bg-custom-light rounded-pill small">Manager</span>
                    </div>
                    <h3 class="h5">Senior Office Manager</h3>
                    <ul class="job-info-list list-inline list-unstyled text-muted">
                        <li class="list-inline-item"><span class="far fa-house-building me-1"></span> Dribble</li>
                        <li class="list-inline-item"><span class="far fa-map-marker-alt me-1"></span> California</li>
                        <li class="list-inline-item"><span class="far fa-wallet me-1"></span> $55k - $62k</li>
                    </ul>
                    <div class="btn btn-outline-light btn-sm d-inline-block mt-4">Apply Now</div>
                </a>
            </div>
            <div class="col-lg-6 col-md-12">
                <a href="career-single.html"
                    class="text-decoration-none mt-4 single-open-job p-5 bg-dark text-white d-block rounded-custom">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="job-time h-6 mb-2"><i class="far fa-briefcase me-2"></i>
                            <strong>Remote</strong></span>
                        <span class="badge px-3 py-2 bg-custom-light rounded-pill small">Developer</span>
                    </div>
                    <h3 class="h5">Senior Backend Developer</h3>
                    <ul class="job-info-list list-inline list-unstyled text-muted">
                        <li class="list-inline-item"><span class="far fa-house-building me-1"></span> Slack</li>
                        <li class="list-inline-item"><span class="far fa-map-marker-alt me-1"></span> United State US
                        </li>
                        <li class="list-inline-item"><span class="far fa-wallet me-1"></span> $55k - $62k</li>
                    </ul>
                    <div class="btn btn-outline-light btn-sm d-inline-block mt-4">Apply Now</div>
                </a>
            </div>
        </div>
    </div>
</section>
<!--open jobs section end-->

<!--career promo start-->
<section class="career-promo ptb-120 bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12">
                <div class="section-heading text-center">
                    <h4 class="h5 text-primary">Why Join Us</h4>
                    <h2>Great Working Environment</h2>
                    <p>Revolutionary paradigms before enabled interfaces dynamically transition technically sound
                        paradigms with cutting-edge initiatives. </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-6">
                <div class="single-feature d-flex mt-0 p-5 bg-white rounded-custom">
                    <span class="fas fa-house-user fa-2x text-primary"></span>
                    <div class="ms-4 mt-2">
                        <h5>Remote Working Facilities</h5>
                        <p class="mb-0">Credibly syndicate enterprise total linkage whereas cost effective innovate
                            state of the art data without multifunctional. </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="single-feature d-flex mt-4 mt-xl-0 mt-lg-0 mt-md-0 p-5 bg-white rounded-custom">
                    <span class="fas fa-clock fa-2x text-primary"></span>
                    <div class="ms-4 mt-2">
                        <h5>Flexible Working Hours</h5>
                        <p class="mb-0">Credibly syndicate enterprise total linkage whereas cost effective innovate
                            state of the art data without multifunctional. </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="single-feature d-flex mt-4 p-5 bg-white rounded-custom">
                    <span class="fas fa-user-friends fa-2x text-primary"></span>
                    <div class="ms-4 mt-2">
                        <h5>Friendly Skilled Team</h5>
                        <p class="mb-0">Credibly syndicate enterprise total linkage whereas cost effective innovate
                            state of the art data without multifunctional. </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="single-feature d-flex mt-4 p-5 bg-white rounded-custom">
                    <span class="fas fa-briefcase-medical fa-2x text-primary"></span>
                    <div class="ms-4 mt-2">
                        <h5>Medical Insurance Facilities</h5>
                        <p class="mb-0">Credibly syndicate enterprise total linkage whereas cost effective innovate
                            state of the art data without multifunctional. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--career promo end-->


<!--cat subscribe start-->
<section class="cta-subscribe bg-dark ptb-120 position-relative overflow-hidden">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="subscribe-info-wrap text-center position-relative z-2">
                    <div class="section-heading">
                        <h4 class="h5 text-pink">Let's Try! Get Free Support</h4>
                        <h2>Start Your 14-Day Free Trial</h2>
                        <p>We can help you to create your dream website for better business revenue.</p>
                    </div>
                    <div class="form-block-banner mw-60 m-auto mt-5">
                        <a href="contact-us.html" class="btn btn-primary">Contact with Us</a>
                        <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                            class="text-decoration-none popup-youtube d-inline-flex align-items-center watch-now-btn ms-lg-3 ms-md-3 mt-3 mt-lg-0">
                            <i class="fas fa-play"></i> Watch Demo </a>
                    </div>
                    <ul class="nav justify-content-center subscribe-feature-list mt-4">
                        <li class="nav-item">
                            <span><i class="far fa-check-circle text-primary me-2"></i>Free 14-day trial</span>
                        </li>
                        <li class="nav-item">
                            <span><i class="far fa-check-circle text-primary me-2"></i>No credit card required</span>
                        </li>
                        <li class="nav-item">
                            <span><i class="far fa-check-circle text-primary me-2"></i>Support 24/7</span>
                        </li>
                        <li class="nav-item">
                            <span><i class="far fa-check-circle text-primary me-2"></i>Cancel anytime</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5"></div>
        <div class="bg-circle rounded-circle circle-shape-1 position-absolute bg-pinkright-5"></div>
    </div>
</section>
