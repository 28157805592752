import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyTag } from 'src/app/models/propertyTag.model';
import { Seo } from 'src/app/models/seo.model';
import { MetaTag } from 'src/app/models/tag.model';
import { SEOService } from 'src/app/services/seo.service';
import { Article, ARTICLES } from '../../../models/article';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  bg = "assets/images/slider/header.jpg"
  articles: Article[] = []
  currentPage = 1
  numberPages = 1
  totalPages = 1
  maxArticles = 12
  currentCategory = "All"
  propertyTags: PropertyTag[]
  metaTags: MetaTag[]
  seo: Seo
  schema
  constructor(private route: ActivatedRoute,
    private router: Router,
    private seoService: SEOService) {
      this.metaTags = [
        {name: "title", content: "Hiis | Senior Software Developer & Data Scientist"},
        {name: "description", content: "Welcome to my Blog, where I write about Scala, functional programming, distributed systems (Akka), big data (Apache Spark) and the JVM."},
        {name: "author", content: "Hiis"},
  
        //Twitter Tags
        {name: "twitter:card", content: "summary_large_image"},
        {name: "twitter:type", content: "website"},
        {name: "twitter:url", content: "https://www.abanda.me"},
        {name: "twitter:title", content: "Hiis | Senior Software Developer & Data Scientist"},
        {name: "twitter:description", content: "Welcome to my Blog, where I write about Scala, functional programming, distributed systems (Akka), big data (Apache Spark) and the JVM."},
        {name: "twitter:image", content: "https://www.abanda.me/assets/images/slider/header.jpg"},
        {name: "twitter:creator", content: "@abanda_ludovic"}
      ]
  
      this.propertyTags = [
        //Facebook / Open Graph Tags
        {property: "og:type", content: "website"},
        {property: "og:url", content: "https://www.abanda.me"},
        {property: "og:title", content: "Hiis | Senior Software Developer & Data Scientist"},
        {property: "og:description", content: "Welcome to my Blog, where I write about Scala, functional programming, distributed systems (Akka), big data (Apache Spark) and the JVM."},
        {property: "og:image", content: "https://www.abanda.me/assets/images/slider/header.jpg"},
      ]
      this.articles = new Array()
      let sorted = ARTICLES.sort((a, b) =>  -(b.date.getMilliseconds() - a.date.getMilliseconds()))
      for (let i = 0; i < 12 && i < sorted.length; i++)
        this.articles.push(sorted[i])

        this.seo = new Seo()
        this.seo.title = "Hiis | Senior Software Developer & Data Scientist"
        this.seo.description = "Welcome to my Blog, where I write about Scala, functional programming, distributed systems (Akka), big data (Apache Spark) and the JVM."
        this.seo.metaTags = this.metaTags
        this.seo.propertyTags = this.propertyTags
    }

  ngOnInit(): void {
    this.seoService.addTags(this.seo)
  }

  changeCategory(category: string) {

  }

  next() {

  }

  previous() {

  }

}
