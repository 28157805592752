export class Article {
    slug: string
    date: Date
    modified: Date
    title: string
    description: string
    image: string
    category: string
    tags: string[]
    file: string
}

export const enum  Category {
    AKKA = "Akka",
    PLAY_FRAMEWORK = "Play Framework",
    SCALA = "Scala",
    ZIO = "Zio",
    KAFKA = "Kafka",
    ANGULAR = "Angular",
    OTHERS = "DevOps"
}

export const enum Tag {
    PLAY_FRAMEWORK = "Play Framework",
    REST_API = "REST API",
    GRAPH_QL = "Graphql",
    AKKA_HTTP = "Akka HTTP",
    AKKA_STREAMS = "Akka Streams",
    AKKA_ACTORS = "Akka Actors",
    AKKA_PERSISTENCE = "Akka Persistence",
    ZIO = "Zio",
    KAFKA = "Kafka",
    ANGULAR = "Angular",
    REACT = "React",
    ANDROID = "Android",
    IOS = "IOS",
    SCALA = "Scala",
    JAVA = "Java",
    TYPESCRIPT = "Typescript",
    DOCKER = "Docker",
}

export const ARTICLES: Article[] = [
    {
        slug: "what-is-zio", date: new Date("2022-02-11T20:00:00Z"), modified: new Date("2022-02-11T20:00:00Z"),
        title: "What is Zio (Scala)", description: "ZIO is a zero-dependency library for asynchronous and concurrent programming in Scala.",
        image: "assets/blog/images/welcome-zio.png", category: Category.ZIO, tags: [Tag.SCALA, Tag.ZIO],
        file: "assets/blog/articles/what-is-zio.md"
    },
    {
        slug: "whats-new-scala-3", date: new Date("2022-02-11T22:00:00Z"), modified: new Date("2022-02-11T22:00:00Z"),
        title: "What's new in Scala 3", description: "Scala 3 brings many improvements and new features. Here I try to provide you with a quick overview of the most important changes.",
        image: "assets/blog/images/scala_3.png", category: Category.SCALA, tags: [Tag.SCALA],
        file: "assets/blog/articles/whats-new-scala3.md"
    },
    
    {
        slug: "deploy-angular-for-free", date: new Date("2022-02-12T02:00:00Z"), modified: new Date("2022-02-12T02:00:00Z"),
        title: "How to deploy an angular app for free", description: "In this guide, you will learn how to deploy an Angular application to Cloudflare pages for free.",
        image: "assets/blog/images/angular.png", category: Category.ANGULAR, tags: [Tag.ANGULAR, Tag.TYPESCRIPT],
        file: "assets/blog/articles/deploy-angular-free.md"
    },
]