import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desktop-development',
  templateUrl: './desktop-development.component.html',
  styleUrls: ['./desktop-development.component.scss']
})
export class DesktopDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
