<ngx-json-ld [json]="schema"></ngx-json-ld>
<!-- Start Slider Area -->
<div id="home" class="rn-slider-area">
    <div class="slide slider-style-4 instructor-fitness bg_image" [style.background-image]="'url('+bg+')'">
        <div class="container">
            <div class="row justify-content-center text-left">
                <div class="col-lg-12">
                    <div class="content">
                        <div class="inner mb_sm--80 mt--10">
                            <h5 class="title color-lightn">{{article.category}}</h5>
                            <span> <a href="../">Home</a> > <a href="../blog"> Blog</a>> Article</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Start Client Area -->
<div class="rn-client-area rn-section-gap section-separator" id="blog">
    <div class="container">

        <div class="row row--25 mt--50 mt_md--40 mt_sm--40">
            <div class="col-lg-8 mt--40">
                <div class="col-lg-12">
                    <div class="section-title">
                        <h1 class="title-post">{{article.title}}</h1>
                    </div>
                </div>
                <div class="tab-area">
                    <markdown src="{{article.file}}">
                    </markdown>
                </div>
            </div>
            <div class="col-lg-4 mt--10">
                <div class="d-flex flex-wrap align-content-start h-100">
                    <div class="position-sticky clients-wrapper sticky-top rbt-sticky-top-adjust">
                        <div class="row mt--30 mb--30 pl--10 pr--10">
                            <div class="col-12 text-center">
                                <div class="contact-about-area">
                                    <div>
                                        <img src="assets/images/logo/abanda-round.png" alt="Hiis">
                                    </div>
                                    <div class="title-area">
                                        <h4 class="title">Hiis</h4>
                                        <span>Software Developer</span>
                                    </div>
                                    <div class="description">
                                        <p>I am a Software Developer, Data Scientist, Open Source enthusiast and
                                            Freelancer. I am passionate about Scala, Big Data & Full Stack Web
                                            Development. I'm open for Freelance and Student mentoring.</p>
                                        <span class="phone">Phone: <a href="tel:+237699131434">+237
                                                699-131-434</a></span>
                                        <span class="mail">Email: <a
                                                href="mailto:contact@abanda.me">contact@abanda.me</a></span>
                                    </div>
                                    <div class="social-area">
                                        <div class="name">CONNECT WITH ME</div>
                                        <div class="social-icone">
                                            <a href="https://twitter.com/abanda_ludovic/" target="_blanc"><i data-feather="twitter"></i></a>
                                            <a href="https://www.linkedin.com/in/ludovic-abanda-8b391a142" target="_blanc"><i data-feather="linkedin"></i></a>
                                            <a href="https://github.com/icemc" target="_blanc"><i data-feather="github"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End client section -->