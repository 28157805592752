import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogPostComponent } from './components/pages/blog-post/blog-post.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { CareerComponent } from './components/pages/career/career.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { HomeComponent } from './components/pages/home/home.component';
import { PortfolioComponent } from './components/pages/portfolio/portfolio.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { BackendDevelopmentComponent } from './components/pages/services/backend-development/backend-development.component';
import { CustomSoftwareDevelopmentComponent } from './components/pages/services/custom-software-development/custom-software-development.component';
import { FrontendDevelopmentComponent } from './components/pages/services/frontend-development/frontend-development.component';
import { MobileDevelopmentComponent } from './components/pages/services/mobile-development/mobile-development.component';
import { ServicesComponent } from './components/pages/services/services/services.component';
import { TeamExtensionComponent } from './components/pages/services/team-extension/team-extension.component';
import { TrainingComponent } from './components/pages/services/training/training.component';
import { AndroidComponent } from './components/pages/technologies/android/android.component';
import { AngularComponent } from './components/pages/technologies/angular/angular.component';
import { ElectronComponent } from './components/pages/technologies/electron/electron.component';
import { FlutterComponent } from './components/pages/technologies/flutter/flutter.component';
import { IonicComponent } from './components/pages/technologies/ionic/ionic.component';
import { IosComponent } from './components/pages/technologies/ios/ios.component';
import { JavaComponent } from './components/pages/technologies/java/java.component';
import { JavafxComponent } from './components/pages/technologies/javafx/javafx.component';
import { NodeComponent } from './components/pages/technologies/node/node.component';
import { PythonComponent } from './components/pages/technologies/python/python.component';
import { ReactNativeComponent } from './components/pages/technologies/react-native/react-native.component';
import { ReactComponent } from './components/pages/technologies/react/react.component';
import { ScalaComponent } from './components/pages/technologies/scala/scala.component';
import { TechnologiesComponent } from './components/pages/technologies/technologies/technologies.component';
import { VueComponent } from './components/pages/technologies/vue/vue.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: { title: 'Hiis |Software Company' } },
  { path: 'careers', component: CareerComponent, data: { title: 'Career | Hiis' } },
  { path: 'about', component: AboutComponent, data: { title: 'About | Hiis' } },
  { path: 'portfolio', component: PortfolioComponent, data: { title: 'Portfolio | Hiis' } },
  { path: 'contact', component: ContactComponent, data: { title: 'Contact | Hiis' } },
  { path: 'blog', component: BlogComponent, data: { title: 'Blog | Hiis' } },
  // { path: 'blog/articles/:id', component: BlogPostComponent },
  { path: 'faq', component: FaqComponent, data: { title: 'FAQ | Hiis' } },
  { path: 'terms-conditions', component: TermsConditionsComponent, data: { title: 'Terms & Conditions | Hiis' } },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy | Hiis' } },
  // { path: 'services', component: ServicesComponent, data: { title: 'Services | Hiis' } },
  { path: 'services/mobile-development', component: MobileDevelopmentComponent, data: { title: 'Mobile App Development | Hiis' } },
  { path: 'services/frontend-development', component: FrontendDevelopmentComponent, data: { title: 'Frontend Development | Hiis' } },
  { path: 'services/backend-development', component: BackendDevelopmentComponent, data: { title: 'Backend Development | Hiis' } },
  { path: 'services/custom-software', component: CustomSoftwareDevelopmentComponent, data: { title: 'Custom Software Development | Hiis' } },
  { path: 'services/team-extension', component: TeamExtensionComponent, data: { title: 'Team Extension | Hiis' } },
  { path: 'services/training', component: TrainingComponent, data: { title: 'Dev Training | Hiis' } }
  // { path: 'technologies', component: TechnologiesComponent, data: { title: 'Technologies | Hiis' } },
  // { path: 'technologies/react-native-app-development', component: ReactNativeComponent, data: { title: 'React Native App Development | Hiis' } },
  // { path: 'technologies/ionic-app-development', component: IonicComponent, data: { title: 'Ionic App Development | Hiis' } },
  // { path: 'technologies/flutter-app-development', component: FlutterComponent, data: { title: 'Flutter App Development | Hiis' } },
  // { path: 'technologies/node-backend-development', component: NodeComponent, data: { title: 'Node Js Backend Development | Hiis' } },
  // { path: 'technologies/python-backend-development', component: PythonComponent, data: { title: 'Python App Development | Hiis' } },
  // { path: 'technologies/java-backend-development', component: JavaComponent, data: { title: 'Java Backend Development | Hiis' } },
  // { path: 'technologies/scala-backend-development', component: ScalaComponent, data: { title: 'Scala Backend Development | Hiis' } },
  // { path: 'technologies/wordpress-website-development', component: TechnologiesComponent, data: { title: 'Wordpress Website Development | Hiis' } },
  // { path: 'technologies/vue-app-development', component: VueComponent, data: { title: 'Vue Web App Development | Hiis' } },
  // { path: 'technologies/react-app-development', component: ReactComponent, data: { title: 'React Web App Development | Hiis' } },
  // { path: 'technologies/angular-app-development', component: AngularComponent, data: { title: 'Angular Web App Development | Hiis' } },
  // { path: 'technologies/javafx-app-development', component: JavafxComponent, data: { title: 'JavaFx Desktop App Development | Hiis' } },
  // { path: 'technologies/electron-app-development', component: ElectronComponent, data: { title: 'Electron App Development | Hiis' } },
  // { path: 'technologies/ios-app-development', component: IosComponent, data: { title: 'IOS App Development | Hiis' } },
  // { path: 'technologies/android-app-development', component: AndroidComponent, data: { title: 'Android App Development | Hiis' } },
  // { path: "*", redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollOffset: [0, 64] // [x, y]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
