<!--coming soon section start-->
<section class="coming-soon-section page-header ptb-120 text-white bg-gradient">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-12">
                <div class="coming-soon-content-wrap position-relative z-2">
                    <h5 class="text-white">Coming Soon...</h5>
                    <h2 class="text-white">Our Blog is still under development!</h2>
                    <div class="action-btns">
                        <a href="../" class="btn btn-primary mt-5 popup-with-form animate__animated animate__shakeX">Go to Home!</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ul class="animated-circle list-unstyled">
        <li class="transition-delay-1 bg-danger"></li>
        <li class="transition-delay-2 bg-warning"></li>
        <li class="transition-delay-3 bg-primary"></li>
    </ul>
</section>
<!--coming soon section end-->
