import { Injectable } from "@angular/core";
import { ScriptStore } from "../models/script.store";

declare var document: any;

@Injectable()
export class ScriptService {

    private scripts: any = {};

    constructor() {
        ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
    }

    load(...scripts: string[]) {
        var promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    loadScript(name: string) {
        if (!this.scripts[name].loaded) {
            const body = <HTMLDivElement>document.body;
            const script = document.createElement('script');
            script.innerHTML = '';
            script.src = this.scripts[name].src;
            script.async = false;
            script.defer = true;
            body.appendChild(script);
        }
    }
}