import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frontend-development',
  templateUrl: './frontend-development.component.html',
  styleUrls: ['./frontend-development.component.scss']
})
export class FrontendDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
