import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-javafx',
  templateUrl: './javafx.component.html',
  styleUrls: ['./javafx.component.scss']
})
export class JavafxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
