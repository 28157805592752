<!--page header section start-->
<section class="page-header ptb-120 text-white bg-gradient">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <h1 class="display-5 fw-bold">Contact Us</h1>
                <p class="lead">Seamlessly actualize client-based users after out-of-the-box value. Globally embrace
                    strategic data through frictionless expertise.</p>
            </div>
        </div>
    </div>
</section>
<!--page header section end-->

<!--contact us promo section start-->
<section class="contact-promo ptb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
                <div
                    class="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
                    <span class="fad fa-comment-alt-lines fa-3x text-primary"></span>
                    <div class="contact-promo-info mb-4">
                        <h5>Chat with us</h5>
                        <p>We've got live Social Experts waiting to help you <strong>monday to friday</strong> from
                            <strong>9am to 5pm EST.</strong>
                        </p>
                    </div>
                    <a href="mailto:hellothemetags@gmail.com" class="btn btn-link mt-auto">Chat with us</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
                <div
                    class="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
                    <span class="fad fa-envelope fa-3x text-primary"></span>
                    <div class="contact-promo-info mb-4">
                        <h5>Email Us</h5>
                        <p>Simple drop us an email at <strong>hellothemetags@gmail.com</strong>
                            and you'll receive a reply within 24 hours</p>
                    </div>
                    <a href="mailto:hellothemetags@gmail.com" class="btn btn-primary mt-auto">Email Us</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
                <div
                    class="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
                    <span class="fad fa-phone fa-3x text-primary"></span>
                    <div class="contact-promo-info mb-4">
                        <h5>Give us a call</h5>
                        <p>Give us a ring.Our Experts are standing by <strong>monday to friday</strong> from
                            <strong>9am to 5pm EST.</strong>
                        </p>
                    </div>
                    <a href="tel:00-976-561-008" class="btn btn-link mt-auto">00-976-561-008</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--contact us promo section end-->