import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyTag } from 'src/app/models/propertyTag.model';
import { Seo } from 'src/app/models/seo.model';
import { MetaTag } from 'src/app/models/tag.model';
import { SEOService } from 'src/app/services/seo.service';
import { Article, ARTICLES } from '../../../models/article';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.css']
})
export class BlogPostComponent implements OnInit {

  bg = "assets/images/slider/header.jpg"
  propertyTags: PropertyTag[]
  metaTags: MetaTag[]
  seo: Seo
  schema
  article: Article
  constructor(private route: ActivatedRoute,
    private router: Router,
    private seoService: SEOService) {
    this.route.params.subscribe(params => {
      let id: string = params["id"];
      console.log(id)
      this.article = ARTICLES.filter(a => a.slug === id)[0]
      if (!this.article) {
        this.router.navigate(["/"]);
      } else {
        this.metaTags = [
          { name: "title", content: this.article.title },
          { name: "description", content: this.article.description },
          { name: "author", content: "Hiis" },

          //Twitter Tags
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:type", content: "article" },
          { name: "twitter:url", content: `https://www.abanda.me/blog/articles/${this.article.slug}` },
          { name: "twitter:title", content: this.article.title },
          { name: "twitter:description", content: this.article.description },
          { name: "twitter:image", content: `https://www.abanda.me/${this.article.image}` },
          { name: "twitter:creator", content: "@abanda_ludovic" }
        ]

        this.propertyTags = [
          //Facebook / Open Graph Tags
          { property: "og:type", content: "article" },
          { property: "og:url", content: `https://www.abanda.me/blog/articles/${this.article.slug}` },
          { property: "og:title", content: this.article.title },
          { property: "og:description", content: this.article.description },
          { property: "og:image", content: `https://www.abanda.me/${this.article.image}` }
        ]

        this.schema = {
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "headline": this.article.title,
          "image": [`https://www.abanda.me/${this.article.image}`
          ],
          "datePublished": this.article.date.toISOString,
          "dateModified": this.article.modified.toISOString,
          "author": [{
            "@type": "Person",
            "name": "Hiis",
            "url": "https://www.abanda.me"
          }]
        }

        this.seo = new Seo()
        this.seo.title = this.article.title
        this.seo.description = this.article.description
        this.seo.metaTags = this.metaTags
        this.seo.propertyTags = this.propertyTags
      }
    })
  }

  ngOnInit(): void {
    this.seoService.addTags(this.seo)
  }

}
