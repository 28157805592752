<!--about header section start-->
<section class="hero-section ptb-120 text-white bg-gradient"
    style="background: url('assets/img/page-header-bg.svg') no-repeat center right">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-heading-wrap d-flex justify-content-between z-5">
                    <div class="col-md-6 col-sm-12">
                        <div class="about-content-left">
                            <div class="about-info mb-5">
                                <h1 class="fw-bold display-5">Full-service software development company</h1>
                                <p class="lead">Build world-class digital products with a team of design, development
                                    and
                                    strategy experts. All in one place.</p>
                                <a href="../career" class="btn btn-primary mt-4 me-3">Open Positions</a>
                                <a href="../contact" class="btn btn-soft-primary mt-4">Contact Us</a>
                            </div>
                            <h2>Web App design</h2>
                            <img src="assets/images/about/preview.gif" alt="about"
                                class="img-fluid about-img-first mt-5 rounded-custom shadow">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="mt-100">
                            <h2 class="mb-20">Mobile App design</h2>
                            <img src="assets/images/about/ui-sketch.gif" alt="about"
                                class="img-fluid mb-5 rounded-custom shadow">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z-2 py-5">
    </div>
</section>
<!--about header section end-->

<!--our story section start-->
<section class="our-story-section pt-60 pb-120"
    style="background: url('assets/img/shape/dot-dot-wave-shape.svg') no-repeat left bottom">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-lg-5 col-md-12 order-lg-1">
                <div class="section-heading sticky-sidebar">
                    <h4 class="h5 text-primary">Our Story</h4>
                    <h2>More than just
                        a software house</h2>
                    <p>Hiis is a place where over 10 passionate professionals align your business goals with the latest
                        tech solutions. We are young, daring, and we want to change the world. How? By pairing up our
                        most brilliant talents with our most demanding clients. Meet the team behind the most
                        customer-oriented software company you’ve ever worked with – and get on board</p>
                    <div class="mt-4">
                        <h6 class="mb-3">We Are Awarded By-</h6>
                        <img src="assets/img/awards-01.svg" alt="awards" class="me-4 img-fluid">
                        <img src="assets/img/awards-02.svg" alt="awards" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-lg-0">
                <div class="story-grid-wrapper position-relative">
                    <!--animated shape start-->
                    <ul class="position-absolute animate-element parallax-element shape-service d-none d-lg-block">
                        <li class="layer" data-depth="0.02">
                            <img src="assets/img/color-shape/image-2.svg" alt="shape"
                                class="img-fluid position-absolute color-shape-2 z-5">
                        </li>
                        <li class="layer" data-depth="0.03">
                            <img src="assets/img/color-shape/feature-3.svg" alt="shape"
                                class="img-fluid position-absolute color-shape-3">
                        </li>
                    </ul>
                    <!--animated shape end-->
                    <div class="story-grid rounded-custom bg-dark overflow-hidden position-relative">
                        <div class="story-item bg-light border">
                            <h3 class="display-5 fw-bold mb-1 text-success">4.8</h3>
                            <h6 class="mb-0">Client Rating</h6>
                        </div>
                        <div class="story-item bg-white border">
                            <h3 class="display-5 fw-bold mb-1 text-primary">10+</h3>
                            <h6 class="mb-0">Team Members</h6>
                        </div>
                        <div class="story-item bg-white border">
                            <h3 class="display-5 fw-bold mb-1 text-dark">1.5M+</h3>
                            <h6 class="mb-0">Lines of Code</h6>
                        </div>
                        <div class="story-item bg-light border">
                            <h3 class="display-5 fw-bold mb-1 text-pink">3+</h3>
                            <h6 class="mb-0">Years In Business</h6>
                        </div>
                        <div class="story-item bg-light border">
                            <h3 class="display-5 fw-bold mb-1 text-danger">20+</h3>
                            <h6 class="mb-0">Clients Worldwide</h6>
                        </div>
                        <div class="story-item bg-white border">
                            <h3 class="display-5 fw-bold mb-1 text-primary">30+</h3>
                            <h6 class="mb-0">Projects Completed</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--our story section end-->

<!--why choose us section start-->
<section class="feature-section ptb-100">
    <div class="container">
        <div class="row align-content-center justify-content-between">
            <div class="col-lg-5 col-md-12">
                <div class="section-heading mb-5">
                    <div class="icon-box rounded-custom bg-danger shadow-sm d-inline-block">
                        <i class="fal fa-shield-check fa-2x text-white"></i>
                    </div>
                    <h2>Increase Your Business Success With Technology</h2>
                    <p>We transform bold business ideas into exceptional digital products. Searching for a partner that
                        will take the process of software development off your hands? You’ve come to the right place. We
                        ideate, design, and develop data-driven digital products made to answer business challenges. We
                        offer 360° services to smoothly guide you on your way to creating a seamless digital
                        masterpiece.</p>
                </div>
                <div class="single-feature d-flex mb-4">
                    <span class="fad fa-bezier-curve fa-2x text-primary"></span>
                    <div class="ms-4 mt-2">
                        <h5>Near Perfect Design</h5>
                        <p>You will definitely love the designs we will propose for your project. Hiis has engineers who are specialized only in UI and UX, they will make sure your project looks astounding to your users. </p>
                    </div>
                </div>
                <div class="single-feature d-flex mb-4">
                    <span class="fad fa-code fa-2x text-primary"></span>
                    <div class="ms-4 mt-2">
                        <h5>Quick Development Time</h5>
                        <p>Efficiently carry out our development projects without negatively impacting on quality. We strive at developing highly scalable softwares withing a short time frame. The Hiis team makes use of the best modern technologies available at the moment. </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-8">
                <div class="feature-shape-img position-relative mt-5 mt-lg-0">
                    <div class="img-bg-shape position-absolute">
                        <img src="assets/img/integations/shape.svg" alt="integations">
                    </div>
                    <img src="assets/img/dashkit.png" class="img-fluid skewed-img-right rounded-custom shadow-lg"
                        alt="softtent">
                </div>
            </div>
        </div>
    </div>
</section>
<!--why choose us section end-->

<!--feature section two start-->
<section class="feature-section-two ptb-100 bg-light">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-md-12">
                <div class="section-heading">
                    <h4 class="h5 text-primary">Our Expertise</h4>
                    <h2>What we are best at!</h2>
                    <p>We are a team of young professionals passionate about what we do – both in and outside of the
                        office. We do not hesitate to lay our hands on the most prominent technologies in the software
                        industry be it new or old.</p>
                    <ul class="list-unstyled mt-5">
                        <li class="d-flex align-items-start mb-4">
                            <div class="icon-box bg-primary rounded-circle me-4">
                                <i class="fa fa-mobile-button text-white"></i>
                            </div>
                            <div class="icon-content">
                                <h3 class="h5">Mobile App Design</h3>
                                <p>It takes lots of effort, planning and research to create an app that succeeds. We
                                    want to take the work off your shoulders. Our UIs are beautiful and intuitive your
                                    users will love it.
                                </p>
                            </div>
                        </li>
                        <li class="d-flex align-items-start mb-4">
                            <div class="icon-box bg-danger rounded-circle me-4">
                                <i class="fab fa-html5 text-white"></i>
                            </div>
                            <div class="icon-content">
                                <h3 class="h5">Web 2 & Web 3 Apps</h3>
                                <p>Building professional grade Web Apps requires a lot of efforts and steps to get it
                                    right, reason why we are here to offer our expertise in building highly scalable Web
                                    apps be it Web 2 or Web 3 (Smart Contracts).
                                </p>
                            </div>
                        </li>
                        <li class="d-flex align-items-start mb-4">
                            <div class="icon-box bg-success rounded-circle me-4">
                                <i class="fas devicon-graphql-plain text-white"></i>
                            </div>
                            <div class="icon-content">
                                <h3 class="h5">Enterprise Grade Backend APIs</h3>
                                <p>Today it's customary to design your tech infrastructures using a micro service
                                    architecture. We are here to help you extends your APIs as you wish. Our team is
                                    well versed with several backend languages and frameworks. </p>
                            </div>
                        </li>
                        <li class="d-flex align-items-start mb-4">
                            <div class="icon-box bg-pinkrounded-circle me-4">
                                <i class="fa fa-laptop-code text-white"></i>
                            </div>
                            <div class="icon-content">
                                <h3 class="h5">Custom Softwares</h3>
                                <p>In need of a custom software for your organization? Our team of senior software
                                    engineers can make that possible. We create applications that astound users and
                                    bring measurable business results.
                                </p>
                            </div>
                        </li>
                        <li class="d-flex align-items-start mb-4">
                            <div class="icon-box bg-dark rounded-circle me-4">
                                <i class="fa fa-graduation-cap text-white"></i>
                            </div>
                            <div class="icon-content">
                                <h3 class="h5">Training & Mentoring</h3>
                                <p>Africa has a huge population and demand of software developers around the world keeps
                                    increasing. At Hiis we train young developers in the languages and technologies we
                                    master in order to make them ready for the job market.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-7">
                <div class="feature-img-wrap position-relative d-flex flex-column align-items-end">
                    <ul class="img-overlay-list list-unstyled position-absolute">
                        <li class="d-flex align-items-center bg-success-soft rounded shadow-sm p-3">
                            <i class="fas fa-check bg-primary text-white rounded-circle"></i>
                            <h6 class="mb-0">Tell us about your project</h6>
                        </li>
                        <li class="d-flex align-items-center bg-success-soft rounded shadow-sm p-3">
                            <i class="fas fa-check bg-primary text-white rounded-circle"></i>
                            <h6 class="mb-0">We design a Development plan</h6>
                        </li>
                        <li class="d-flex align-items-center bg-success-soft rounded shadow-sm p-3">
                            <i class="fas fa-check bg-primary text-white rounded-circle"></i>
                            <h6 class="mb-0">Get a MVP in about 3 Months</h6>
                        </li>
                    </ul>
                    <img src="assets/images/about/show-case.gif" alt="feature image" class="img-fluid rounded-custom">
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature section two end-->

<!--cat subscribe start-->
<section class="cta-subscribe ptb-50">
    <div class="container">
        <div class="bg-dark ptb-120 position-relative overflow-hidden rounded-custom">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-8">
                    <div class="subscribe-info-wrap text-center position-relative z-2">
                        <div class="section-heading">
                            <h4 class="h5 text-pink">Get Free Support</h4>
                            <h2>Let's talk about your project</h2>
                            <p>We can help implement your project for better business revenue.</p>
                        </div>
                        <div class="form-block-banner mw-60 m-auto mt-5">
                            <a href="../contact" class="btn btn-primary">Contact with Us</a>
                        </div>
                        <ul class="nav justify-content-center subscribe-feature-list mt-4">
                            <li class="nav-item">
                                <span><i class="far fa-check-circle text-primary me-2"></i>We respond in less than 24H</span>
                            </li>
                            <li class="nav-item">
                                <span><i class="far fa-check-circle text-primary me-2"></i>Free tech consultation</span>
                            </li>
                            <li class="nav-item">
                                <span><i class="far fa-check-circle text-primary me-2"></i>Provide an estimate in 3 Days</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5"></div>
            <div class="bg-circle rounded-circle circle-shape-1 position-absolute bg-pinkright-5"></div>
        </div>
    </div>
</section>
<!--cat subscribe end-->
