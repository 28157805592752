<!--hero section start-->
<section class="hero-section ptb-120 bg-dark text-white"
    style="background: url('assets/img/shape/dot-dot-wave-shape.svg')no-repeat bottom left">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-xl-5 col-lg-5">
                <div class="hero-content-wrap text-center text-xl-start text-lg-start mt-5 mt-lg-0 mt-xl-0"
                    data-aos="fade-up">
                    <h1 class="fw-bold display-5">Advanced Solutions for Business</h1>
                    <p class="lead">Proactively coordinate quality quality vectors vis-a-vis supply chains quickly
                        engage client.</p>
                    <div
                        class="hero-subscribe-form-wrap pt-4 position-relative m-auto m-xl-0 d-none d-md-block d-lg-block d-xl-block">
                        <form id="subscribe-form" name="email-form"
                            class="hero-subscribe-form d-block d-lg-flex d-md-flex">
                            <input type="email" class="form-control me-2" name="Email" data-name="Email"
                                placeholder="Enter Your Email Address" id="email-address" required="">
                            <input type="submit" value="Subscribe" data-wait="Please wait..."
                                class="btn btn-primary mt-3 mt-lg-0 mt-md-0">
                        </form>
                        <ul class="nav subscribe-feature-list mt-3 justify-content-lg-start justify-content-md-center">
                            <li class="nav-item">
                                <span class="ms-0"><i class="far fa-check-circle text-primary me-2"></i>Free 14-day
                                    trial</span>
                            </li>
                            <li class="nav-item">
                                <span><i class="far fa-check-circle text-primary me-2"></i>No credit card
                                    required</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 mt-lg-5 mt-4">
                <div class="hero-img-wrap position-relative" data-aos="fade-up">
                    <div class="hero-screen-wrap">
                        <div class="phone-screen">
                            <img src="assets/img/screen/phone-screen.png" alt="hero image"
                                class="position-relative img-fluid">
                        </div>
                        <div class="mac-screen">
                            <img src="assets/img/screen/mac-screen.png" alt="hero image"
                                class="position-relative img-fluid rounded-custom">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--hero section end-->

<!--customer section start-->
<div class="customer-section pb-120 bg-dark">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-12">
                <ul class="customer-logos-grid text-center list-unstyled mb-0">
                    <li>
                        <img src="assets/img/clients/client-logo-1.svg" width="150" alt="clients logo"
                            class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto" data-aos="fade-up"
                            data-aos-delay="50">
                    </li>
                    <li>
                        <img src="assets/img/clients/client-logo-2.svg" width="150" alt="clients logo"
                            class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto" data-aos="fade-up"
                            data-aos-delay="50">
                    </li>
                    <li>
                        <img src="assets/img/clients/client-logo-3.svg" width="150" alt="clients logo"
                            class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto" data-aos="fade-up"
                            data-aos-delay="50">
                    </li>
                    <li>
                        <img src="assets/img/clients/client-logo-4.svg" width="150" alt="clients logo"
                            class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto" data-aos="fade-up"
                            data-aos-delay="50">
                    </li>
                    <li>
                        <img src="assets/img/clients/client-logo-5.svg" width="150" alt="clients logo"
                            class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto" data-aos="fade-up"
                            data-aos-delay="100">
                    </li>
                    <li>
                        <img src="assets/img/clients/client-logo-6.svg" width="150" alt="clients logo"
                            class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto" data-aos="fade-up"
                            data-aos-delay="100">
                    </li>
                    <li>
                        <img src="assets/img/clients/client-logo-7.svg" width="150" alt="clients logo"
                            class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto" data-aos="fade-up"
                            data-aos-delay="100">
                    </li>
                    <li>
                        <img src="assets/img/clients/client-logo-8.svg" width="150" alt="clients logo"
                            class="img-fluid customer-logo p-1 p-md-2 p-lg-3 m-auto" data-aos="fade-up"
                            data-aos-delay="100">
                    </li>
                </ul>
                <p class="text-center mt-lg-5 mt-4 mb-0" data-aos="fade-up" data-aos-delay="200">Trusted More than
                    25,00+ Companies Around the World</p>
            </div>
        </div>
    </div>
</div>
<!--customer section end-->

<!--highlight promo start-->
<section class="promo-section ptb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-6">
                <div class="section-heading text-center" data-aos="fade-up">
                    <h2>Everything We Do it With Love</h2>
                    <p>Progressively deploy market positioning catalysts for change and technically sound
                        authoritatively e-enable resource-leveling infrastructures. </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <div class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-4"
                    data-aos="fade-up" data-aos-delay="50">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4">150+</h3>
                        <h2 class="h5">Completed Projects</h2>
                        <p class="mb-0">Rapidiously embrace distinctive best practices after B2B syndicate. </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-4"
                    data-aos="fade-up" data-aos-delay="100">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4">1.5k</h3>
                        <h2 class="h5">Happy Client</h2>
                        <p class="mb-0">Energistically impact synergistic convergence for leadership..</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-0"
                    data-aos="fade-up" data-aos-delay="150">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4">90%</h3>
                        <h2 class="h5">24/7 Support</h2>
                        <p class="mb-0">Synergistically revolutionize leadership whereas platform. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--highlight promo end-->

<!--feature section start-->
<section class="feature-section-two pt-60 pb-120">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-5 col-md-12">
                <div class="section-heading" data-aos="fade-up">
                    <h4 class="h5 text-primary">Features</h4>
                    <h2>Powerful Solutions for Your Business Needs</h2>
                    <p>Quickly incubate functional channels with multidisciplinary architectures authoritatively
                        fabricate.</p>
                    <ul class="list-unstyled mt-5">
                        <li class="d-flex align-items-start mb-4">
                            <div class="icon-box bg-primary rounded me-4">
                                <i class="fas fa-bezier-curve text-white"></i>
                            </div>
                            <div class="icon-content">
                                <h3 class="h5">Pixel Perfect Design</h3>
                                <p>Progressively foster enterprise-wide systems whereas equity invested
                                    web-readiness harness installed.
                                </p>
                            </div>
                        </li>
                        <li class="d-flex align-items-start mb-4">
                            <div class="icon-box bg-danger rounded me-4">
                                <i class="fas fa-fingerprint text-white"></i>
                            </div>
                            <div class="icon-content">
                                <h3 class="h5">Unique &amp; Minimal Design</h3>
                                <p>Dramatically administrate progressive metrics without error-free globally
                                    simplify standardized.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-7">
                <div class="feature-img-wrap position-relative d-flex flex-column align-items-end">
                    <ul class="img-overlay-list list-unstyled position-absolute">
                        <li class="d-flex align-items-center bg-white rounded shadow-sm p-3" data-aos="fade-up"
                            data-aos-delay="50">
                            <i class="fas fa-check bg-primary text-white rounded-circle"></i>
                            <h6 class="mb-0">Create a Free Account</h6>
                        </li>
                        <li class="d-flex align-items-center bg-white rounded shadow-sm p-3" data-aos="fade-up"
                            data-aos-delay="100">
                            <i class="fas fa-check bg-primary text-white rounded-circle"></i>
                            <h6 class="mb-0">Install Our Tracking Pixel</h6>
                        </li>
                        <li class="d-flex align-items-center bg-white rounded shadow-sm p-3" data-aos="fade-up"
                            data-aos-delay="150">
                            <i class="fas fa-check bg-primary text-white rounded-circle"></i>
                            <h6 class="mb-0">Start Tracking your Website</h6>
                        </li>
                    </ul>
                    <img src="assets/img/feature-img3.jpg" alt="feature image" class="img-fluid rounded-custom"
                        data-aos="fade-up">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="feature-section pt-60 pb-120">
    <div class="container">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-5 order-lg-2 mb-7 mb-lg-0">
                <div class="mb-4" data-aos="fade-up">
                    <h2>Experience your Product with Integration</h2>
                    <p>Conveniently drive stand-alone total linkage for process-centric content enthusiastically
                        administrate robust collaborative. </p>
                </div>
                <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5" data-aos="fade-up" data-aos-delay="50">
                    <li>
                        <span class="d-block mb-4"><i class="fal fa-user fa-2x text-primary"></i></span>
                        <h3 class="h5">Customer analysis</h3>
                        <p>Objectively exceptional via customized capital expertise.</p>
                    </li>
                    <li>
                        <span class="d-block mb-4"><i class="fal fa-watch fa-2x text-primary"></i></span>
                        <h3 class="h5">Real time metrics</h3>
                        <p>Interactively integrate extensible users resource. </p>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6 order-lg-1">
                <div class="pr-lg-4">
                    <div class="bg-light text-center rounded-custom overflow-hidden p-lg-5 p-3 mx-lg-auto"
                        data-aos="fade-up" data-aos-delay="50">
                        <img src="assets/img/dashboard-img.png" alt="" class="img-fluid">
                        <div
                            class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature section end-->

<!--feature service grid section start-->
<section class="feature-section ptb-120 bg-dark text-white ">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="section-heading text-center" data-aos="fade-up">
                    <h2>Best Services Grow Your Business Value</h2>
                    <p>Globally actualize cost effective with resource maximizing leadership skills.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="feature-grid">
                    <div class="feature-card  bg-custom-light promo-border-hover border border-2 border-light text-white shadow-sm highlight-card rounded-custom p-5"
                        data-aos="fade-up" data-aos-delay="50">
                        <div class="icon-box d-inline-block rounded-circle bg-primary-soft mb-32">
                            <i class="fal fa-analytics icon-sm text-primary"></i>
                        </div>
                        <div class="feature-content">
                            <h3 class="h5">Advanced analytics</h3>
                            <p>Synergistically pursue accurate initiatives without economically sound
                                imperatives.</p>
                            <p> Professionally architect unique process improvements via optimal.</p>
                            <h6 class="mt-4">Included with...</h6>
                            <ul class="list-unstyled mb-0">
                                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>High-converting
                                </li>
                                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Personal branding
                                </li>
                                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Modernized
                                    prospecting</li>
                                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Clean and modern
                                </li>
                                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Showcasing success
                                </li>
                                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Bootstrap latest
                                    version</li>
                            </ul>
                        </div>
                        <a href="service-single.html" class="link-with-icon text-decoration-none mt-3">View Details <i
                                class="far fa-arrow-right"></i></a>
                    </div>
                    <div class="feature-card  bg-custom-light promo-border-hover border border-2 border-light text-white shadow-sm rounded-custom p-5"
                        data-aos="fade-up" data-aos-delay="50">
                        <div class="icon-box d-inline-block rounded-circle bg-success-soft mb-32">
                            <i class="fal fa-file-chart-line icon-sm text-success"></i>
                        </div>
                        <div class="feature-content">
                            <h3 class="h5">Automated Reports</h3>
                            <p class="mb-0">Synergistically pursue accurate initiatives without economically
                                imperatives.</p>
                        </div>
                        <a href="service-single.html" class="link-with-icon text-decoration-none mt-3">View Details <i
                                class="far fa-arrow-right"></i></a>
                    </div>
                    <div class="feature-card  bg-custom-light promo-border-hover border border-2 border-light text-white shadow-sm rounded-custom p-5"
                        data-aos="fade-up" data-aos-delay="50">
                        <div class="icon-box d-inline-block rounded-circle bg-danger-soft mb-32">
                            <i class="fal fa-user-friends icon-sm text-danger"></i>
                        </div>
                        <div class="feature-content">
                            <h3 class="h5">User Journey Flow</h3>
                            <p class="mb-0">Quickly productize prospective value before collaborative benefits.</p>
                        </div>
                        <a href="service-single.html" class="link-with-icon text-decoration-none mt-3">View Details <i
                                class="far fa-arrow-right"></i></a>
                    </div>
                    <div class="feature-card  bg-custom-light promo-border-hover border border-2 border-light text-white shadow-sm rounded-custom p-5"
                        data-aos="fade-up" data-aos-delay="50">
                        <div class="icon-box d-inline-block rounded-circle bg-dark-soft mb-32">
                            <i class="fal fa-spell-check icon-sm text-dark"></i>
                        </div>
                        <div class="feature-content">
                            <h3 class="h5">Compare A/B Testing</h3>
                            <p class="mb-0">Credibly disintermediate functional processes for team driven action.</p>
                        </div>
                        <a href="service-single.html" class="link-with-icon text-decoration-none mt-3">View Details <i
                                class="far fa-arrow-right"></i></a>
                    </div>
                    <div class="feature-card  bg-custom-light promo-border-hover border border-2 border-light text-white shadow-sm rounded-custom p-5"
                        data-aos="fade-up" data-aos-delay="50">
                        <div class="icon-box d-inline-block rounded-circle bg-warning-soft mb-32">
                            <i class="fal fa-cog icon-sm text-pink"></i>
                        </div>
                        <div class="feature-content">
                            <h3 class="h5">Easy Customization</h3>
                            <p class="mb-0">Dynamically develop ubiquitous opportunities whereas relationships.</p>
                        </div>
                        <a href="service-single.html" class="link-with-icon text-decoration-none mt-3">View Details <i
                                class="far fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature service grid section end-->

<!--work process section start-->
<section class="work-process ptb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-6">
                <div class="section-heading text-center" data-aos="fade-up">
                    <h4 class="h5 text-primary">Process</h4>
                    <h2>We Follow Our Work Process</h2>
                    <p>Conveniently mesh cooperative services via magnetic outsourcing. Dynamically grow functionalized
                        value whereas accurate e-commerce. </p>
                </div>
            </div>
        </div>
        <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-3">
                <div class="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-3 mb-lg-0"
                    data-aos="fade-up" data-aos-delay="50">
                    <div class="process-icon border border-light bg-custom-light rounded-custom p-3">
                        <span class="h2 mb-0 text-primary fw-bold">1</span>
                    </div>
                    <h3 class="h5">Research</h3>
                    <p class="mb-0">Uniquely pursue restore efficient for initiatives expanded.</p>
                </div>
            </div>
            <div class="dots-line first"></div>
            <div class="col-md-6 col-lg-3">
                <div class="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-3 mb-lg-0"
                    data-aos="fade-up" data-aos-delay="100">
                    <div class="process-icon border border-light bg-custom-light rounded-custom p-3">
                        <span class="h2 mb-0 text-primary fw-bold">2</span>
                    </div>
                    <h3 class="h5">Designing</h3>
                    <p class="mb-0">Restore efficient human pursue for compelling initiatives.</p>
                </div>
            </div>
            <div class="dots-line first"></div>
            <div class="col-md-6 col-lg-3">
                <div class="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-3 mb-lg-0 mb-md-0"
                    data-aos="fade-up" data-aos-delay="150">
                    <div class="process-icon border border-light bg-custom-light rounded-custom p-3">
                        <span class="h2 mb-0 text-primary fw-bold">3</span>
                    </div>
                    <h3 class="h5">Building</h3>
                    <p class="mb-0">Continually enhance pursue compelling initiatives enhance.</p>
                </div>
            </div>
            <div class="dots-line first"></div>
            <div class="col-md-6 col-lg-3">
                <div class="process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-0"
                    data-aos="fade-up" data-aos-delay="200">
                    <div class="process-icon border border-light bg-custom-light rounded-custom p-3">
                        <span class="h2 mb-0 text-primary fw-bold">4</span>
                    </div>
                    <h3 class="h5">Deliver</h3>
                    <p class="mb-0">Uniquely for compelling initiatives expanded interactive.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--work process section end-->

<!--integration section start-->
<section class="integration-section pt-60 pb-120">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 col-md-12">
                <div class="section-heading" data-aos="fade-up">
                    <h4 class="h5 text-primary">Integration</h4>
                    <h2>We Collaborate with Top Software Company</h2>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="text-lg-end mb-5 mb-lg-0" data-aos="fade-up">
                    <a href="integrations.html" class="btn btn-primary">View All Integrations</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="integration-wrapper position-relative w-100">
                    <!--animated shape start-->
                    <ul
                        class="position-absolute animate-element parallax-element shape-service z--1 d-none d-lg-none d-xl-block">

                        <li class="layer" data-depth="0.02">
                            <img src="assets/img/color-shape/feature-2.svg" alt="shape"
                                class="img-fluid position-absolute color-shape-2 z-5">
                        </li>
                        <li class="layer" data-depth="0.03">
                            <img src="assets/img/color-shape/feature-3.svg" alt="shape"
                                class="img-fluid position-absolute color-shape-3">
                        </li>
                    </ul>
                    <!--animated shape end-->
                    <ul class="integration-list list-unstyled mb-0">
                        <li data-aos="fade-up" data-aos-delay="50">
                            <div class="single-integration">
                                <img src="assets/img/integations/airbnb.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="50">
                            <div class="single-integration">
                                <img src="assets/img/integations/figma.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="50">
                            <div class="single-integration">
                                <img src="assets/img/integations/facebook.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="50">
                            <div class="single-integration">
                                <img src="assets/img/integations/sales-force.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="50">
                            <div class="single-integration">
                                <img src="assets/img/integations/atlassian.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="50">
                            <div class="single-integration">
                                <img src="assets/img/integations/dropbox-2.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="single-integration">
                                <img src="assets/img/integations/dynamic-365.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="single-integration">
                                <img src="assets/img/integations/erecruiter.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="single-integration">
                                <img src="assets/img/integations/evernote.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="single-integration">
                                <img src="assets/img/integations/google-icon.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="single-integration">
                                <img src="assets/img/integations/slack.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="single-integration">
                                <img src="assets/img/integations/google-analytics.png" alt="integration"
                                    class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="150">
                            <div class="single-integration">
                                <img src="assets/img/integations/google-drive.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="150">
                            <div class="single-integration">
                                <img src="assets/img/integations/hubspot.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="150">
                            <div class="single-integration">
                                <img src="assets/img/integations/instagram.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="150">
                            <div class="single-integration">
                                <img src="assets/img/integations/linkedin.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="150">
                            <div class="single-integration">
                                <img src="assets/img/integations/mailchimp.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="150">
                            <div class="single-integration">
                                <img src="assets/img/integations/marekto.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!--integration section end-->

<!--customer review tab section start-->
<section class="testimonial-section ptb-120 bg-light">
    <div class="container">
        <div class="row justify-content-center align-content-center">
            <div class="col-md-10 col-lg-6">
                <div class="section-heading text-center" data-aos="fade-up">
                    <h4 class="h5 text-primary">Testimonial</h4>
                    <h2>What They Say About Us</h2>
                    <p>Uniquely promote adaptive quality vectors rather than stand-alone e-markets pontificate
                        alternative architectures with accurate schemas.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="position-relative w-100" data-aos="fade-up" data-aos-delay="50">
                    <div class="swiper-container testimonialSwiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide border border-2 p-5 rounded-custom position-relative">
                                <img src="assets/img/testimonial/quotes-dot.svg" alt="quotes" width="100"
                                    class="img-fluid position-absolute left-0 top-0 z--1 p-3">
                                <div class="d-flex mb-32 align-items-center">
                                    <img src="assets/img/testimonial/1.jpg" class="img-fluid me-3 rounded" width="60"
                                        alt="user">
                                    <div class="author-info">
                                        <h6 class="mb-0">Mr.Rupan Oberoi</h6>
                                        <small>Founder and CEO at Amaara Herbs</small>
                                    </div>
                                </div>
                                <blockquote>
                                    <h6>The Best Template You Got to Have it!</h6>
                                    Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce
                                    cross-media infrastructures rather than ethical
                                </blockquote>
                                <ul class="review-rate mb-0 mt-2 list-unstyled list-inline">
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                </ul>
                                <img src="assets/img/testimonial/quotes.svg" alt="quotes"
                                    class="position-absolute right-0 bottom-0 z--1 pe-4 pb-4">
                            </div>
                            <div class="swiper-slide border border-2 p-5 rounded-custom position-relative">
                                <img src="assets/img/testimonial/quotes-dot.svg" alt="quotes" width="100"
                                    class="img-fluid position-absolute left-0 top-0 z--1 p-3">
                                <div class="d-flex mb-32 align-items-center">
                                    <img src="assets/img/testimonial/3.jpg" class="img-fluid me-3 rounded" width="60"
                                        alt="user">
                                    <div class="author-info">
                                        <h6 class="mb-0">Oberoi R.</h6>
                                        <small>CEO at Herbs</small>
                                    </div>
                                </div>
                                <blockquote>
                                    <h6>Embarrassed by the First Version.</h6>
                                    Dynamically create innovative core competencies with effective best
                                    practices promote innovative infrastructures.
                                </blockquote>
                                <ul class="review-rate mb-0 mt-2 list-unstyled list-inline">
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                </ul>
                                <img src="assets/img/testimonial/quotes.svg" alt="quotes"
                                    class="position-absolute right-0 bottom-0 z--1 pe-4 pb-4">
                            </div>
                            <div class="swiper-slide border border-2 p-5 rounded-custom position-relative">
                                <img src="assets/img/testimonial/quotes-dot.svg" alt="quotes" width="100"
                                    class="img-fluid position-absolute left-0 top-0 z--1 p-3">
                                <div class="d-flex mb-32 align-items-center">
                                    <img src="assets/img/testimonial/2.jpg" class="img-fluid me-3 rounded" width="60"
                                        alt="user">
                                    <div class="author-info">
                                        <h6 class="mb-0">Mr.Rupan Oberoi</h6>
                                        <small>Founder and CEO</small>
                                    </div>
                                </div>
                                <blockquote>
                                    <h6>Amazing Quiety template!</h6>
                                    Appropriately negotiate interactive niches rather than parallel strategic theme
                                    incubate premium total linkage areas.
                                </blockquote>
                                <ul class="review-rate mb-0 mt-2 list-unstyled list-inline">
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                </ul>
                                <img src="assets/img/testimonial/quotes.svg" alt="quotes"
                                    class="position-absolute right-0 bottom-0 z--1 pe-4 pb-4">
                            </div>
                            <div class="swiper-slide border border-2 p-5 rounded-custom position-relative">
                                <img src="assets/img/testimonial/quotes-dot.svg" alt="quotes" width="100"
                                    class="img-fluid position-absolute left-0 top-0 z--1 p-3">
                                <div class="d-flex mb-32 align-items-center">
                                    <img src="assets/img/testimonial/4.jpg" class="img-fluid me-3 rounded" width="60"
                                        alt="user">
                                    <div class="author-info">
                                        <h6 class="mb-0">Joan Dho</h6>
                                        <small>Founder and CTO</small>
                                    </div>
                                </div>
                                <blockquote>
                                    <h6>Best Template for SAAS Company!</h6>
                                    Dynamically create innovative core competencies with effective best
                                    practices promote innovative infrastructures.
                                </blockquote>
                                <ul class="review-rate mb-0 mt-2 list-unstyled list-inline">
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                </ul>
                                <img src="assets/img/testimonial/quotes.svg" alt="quotes"
                                    class="position-absolute right-0 bottom-0 z--1 pe-4 pb-4">
                            </div>
                            <div class="swiper-slide border border-2 p-5 rounded-custom position-relative">
                                <img src="assets/img/testimonial/quotes-dot.svg" alt="quotes" width="100"
                                    class="img-fluid position-absolute left-0 top-0 z--1 p-3">
                                <div class="d-flex mb-32 align-items-center">
                                    <img src="assets/img/testimonial/5.jpg" class="img-fluid me-3 rounded" width="60"
                                        alt="user">
                                    <div class="author-info">
                                        <h6 class="mb-0">Ranu Mondal</h6>
                                        <small>Lead Developer</small>
                                    </div>
                                </div>
                                <blockquote>
                                    <h6>It is undeniably good!</h6>
                                    Rapidiously supply client-centric e-markets and maintainable processes progressively
                                    engineer
                                </blockquote>
                                <ul class="review-rate mb-0 mt-2 list-unstyled list-inline">
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                    <li class="list-inline-item"><i class="fas fa-star text-pink"></i></li>
                                </ul>
                                <img src="assets/img/testimonial/quotes.svg" alt="quotes"
                                    class="position-absolute right-0 bottom-0 z--1 pe-4 pb-4">
                            </div>
                        </div>
                    </div>
                    <span class="swiper-button-next"></span>
                    <span class="swiper-button-prev"></span>
                </div>
            </div>
        </div>
    </div>
</section>
<!--customer review tab section end-->

<!--cat subscribe start-->
<section class="cta-subscribe bg-dark text-white ptb-120 position-relative overflow-hidden">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="subscribe-info-wrap text-center position-relative z-2">
                    <div class="section-heading" data-aos="fade-up">
                        <h4 class="h5 text-pink">Let's Try! Get Free Support</h4>
                        <h2>Start Your 14-Day Free Trial</h2>
                        <p>We can help you to create your dream website for better business revenue.</p>
                    </div>
                    <div class="form-block-banner mw-60 m-auto mt-5" data-aos="fade-up" data-aos-delay="50">
                        <a href="contact-us.html" class="btn btn-primary">Contact with Us</a>
                        <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                            class="text-decoration-none popup-youtube d-inline-flex align-items-center watch-now-btn ms-lg-3 ms-md-3 mt-3 mt-lg-0">
                            <i class="fas fa-play"></i> Watch Demo </a>
                    </div>
                    <ul class="nav justify-content-center subscribe-feature-list mt-4" data-aos="fade-up"
                        data-aos-delay="100">
                        <li class="nav-item">
                            <span><i class="far fa-check-circle text-primary me-2"></i>Free 14-day trial</span>
                        </li>
                        <li class="nav-item">
                            <span><i class="far fa-check-circle text-primary me-2"></i>No credit card required</span>
                        </li>
                        <li class="nav-item">
                            <span><i class="far fa-check-circle text-primary me-2"></i>Support 24/7</span>
                        </li>
                        <li class="nav-item">
                            <span><i class="far fa-check-circle text-primary me-2"></i>Cancel anytime</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5"></div>
        <div class="bg-circle rounded-circle circle-shape-1 position-absolute bg-pinkright-5"></div>
    </div>
</section>
<!--cat subscribe end-->
