<!--header start-->

<header class="main-header w-100" [ngClass]="dark ? 'position-absolute' : ''">
    <!-- <div class="top-header-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <ul class="top-header-information">
                        <li>
                            <i class="flaticon-pin"></i>
                            1310 Stanley Ave B,Long beach,CA 90804
                        </li>
                        <li>
                            <i class="fa fa-clock"></i>
                            Monday 8:00 AM - 12:00 PM
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6 col-md-12">
                    <ul class="top-header-optional">
                        <li>Currency: <b>USD</b></li>
                        <li>
                            <div class="dropdown language-switcher d-inline-block">
                                <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <span>Language <i class='bx bx-chevron-down'></i></span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/english.png" class="shadow-sm" alt="flag">
                                        <span>English</span>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->
    <nav class="navbar navbar-expand-xl sticky-header"
        [ngClass]="[dark ? 'navbar-dark' : 'navbar-light', sticky ? 'affix' : '']" (scroll)="onscroll()">
        <div class="container d-flex align-items-center justify-content-lg-between position-relative">
            <a routerLink="" class="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                <img src="assets/img/logo-white.png" alt="logo" class="img-fluid logo-white" />
                <img src="assets/img/logo-color.png" alt="logo" class="img-fluid logo-color" />
            </a>

            <button class="navbar-toggler position-absolute right-0 border-0">
                <span class="far fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop"
                    aria-controls="offcanvasWithBackdrop"></span>
            </button>
            <div class="clearfix"></div>
            <div class="collapse navbar-collapse justify-content-center">
                <ul class="nav col-12 col-md-auto justify-content-center main-menu">
                    <li><a routerLink="" class="nav-link">Home</a></li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Services
                        </a>
                        <div class="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                            <div class="dropdown-grid rounded-custom width-half">
                                <div class="dropdown-grid-item">
                                    <a routerLink="/services/mobile-development" class="dropdown-link px-0">
                                        <span class="me-2"><i class="fa fa-mobile-button"></i></span>
                                        <div class="drop-title">Mobile Development</div>
                                    </a>
                                    <a routerLink="/services/frontend-development" class="dropdown-link px-0">
                                        <span class="me-2"><i class="fab fa-html5"></i></span>
                                        <div class="drop-title">Frontend Development</div>
                                    </a>
                                    <a routerLink="/services/backend-development" class="dropdown-link px-0">
                                        <span class="me-2"><i class="devicon-graphql-plain"></i></span>
                                        <div class="drop-title">Backend Development</div>
                                    </a>
                                    <a routerLink="/services/custom-software" class="dropdown-link px-0">
                                        <span class="me-2"><i class="fa fa-laptop-code"></i></span>
                                        <div class="drop-title">Custom Software Development</div>
                                    </a>
                                    <!-- <a routerLink="/services/team-extension" class="dropdown-link px-0">
                                        <span class="me-2"><i class="fad fa-poll-people"></i></span>
                                        <div class="drop-title">Team Extension</div>
                                    </a> -->
                                    <a routerLink="/services/training" class="dropdown-link px-0">
                                        <span class="me-2"><i class="fa fa-graduation-cap"></i></span>
                                        <div class="drop-title">Training</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">Technologies</a>
                        <div class="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                            <div class="dropdown-grid rounded-custom width-full-3">
                                <div class="dropdown-grid-item">
                                    <h6 class="drop-heading"><span class="me-2"><i
                                                class="fa fa-mobile-button"></i></span> Mobile</h6>
                                    <a routerLink="/technologies/android-app-development" class="dropdown-link px-0">
                                        <span class="me-2"><i class="devicon-android-plain"></i></span>
                                        <div class="drop-title">Android</div>
                                    </a>
                                    <a routerLink="/technologies/ios-app-development" class="dropdown-link px-0">
                                        <span class="me-2"><i class="devicon-apple-plain"></i></span>
                                        <div class="drop-title">IOS</div>
                                    </a>
                                    <h6 class="drop-heading"><span class="me-2"><i class="fab fa-html5"></i></span>
                                        Frontend</h6>
                                    <a routerLink="/technologies/angular-app-development" class="dropdown-link px-0">
                                        <span class="me-2"><i class="devicon-angularjs-plain"></i></span>
                                        <div class="drop-title">Angular</div>
                                    </a>
                                    <a routerLink="/technologies/react-app-development" class="dropdown-link px-0">
                                        <span class="me-2"><i class="devicon-react-plain"></i></span>
                                        <div class="drop-title">React</div>
                                    </a>
                                    <a routerLink="/technologies/wordpress-app-development" class="dropdown-link px-0">
                                        <span class="me-2"><i class="devicon-wordpress-plain"></i></span>
                                        <div class="drop-title">Wordpress</div>
                                    </a>
                                </div>
                                <div class="dropdown-grid-item">
                                    <h6 class="drop-heading"><span class="me-2"><i
                                                class="devicon-graphql-plain"></i></span> Backend</h6>
                                    <a routerLink="/technologies/scala-backend-development" class="dropdown-link px-0">
                                        <span class="me-2"><i class="devicon-scala-plain"></i></span>
                                        <div class="drop-title">Scala</div>
                                    </a>
                                    <a routerLink="/technologies/python-backend-development" class="dropdown-link px-0">
                                        <span class="me-2"><i class="devicon-python-plain"></i></span>
                                        <div class="drop-title">Python</div>
                                    </a>
                                    <a routerLink="/technologies/node-backend-development" class="dropdown-link px-0">
                                        <span class="me-2"><i class="devicon-nodejs-plain"></i></span>
                                        <div class="drop-title">Node.js</div>
                                    </a>
                                    <h6 class="drop-heading"><span class="me-2"><i
                                                class="fa fa-mobile-iphone"></i></span> Cross-Platform</h6>
                                    <a routerLink="/technologies/flutter-app-development" class="dropdown-link px-0">
                                        <span class="me-2"><i class="devicon-flutter-plain"></i></span>
                                        <div class="drop-title">Flutter</div>
                                    </a>
                                    <a routerLink="/technologies/react-native-app-development"
                                        class="dropdown-link px-0">
                                        <span class="me-2"><i class="devicon-react-plain"></i></span>
                                        <div class="drop-title">React Native</div>
                                    </a>
                                </div>
                                <div class="dropdown-grid-item last-item bg-light radius-right-side">
                                    <a routerLink="#"><img src="assets/img/feature-img3.jpg" alt="add"
                                            class="img-fluid rounded-custom" /></a>
                                </div>
                            </div>
                        </div>
                    </li> -->
                    <li><a routerLink="/about" class="nav-link">About</a></li>
                    <li><a routerLink="/faq" class="nav-link">FAQ</a></li>
                    <!-- <li><a routerLink="/careers" class="nav-link">Careers</a></li> -->
                    <!-- <li><a routerLink="/blog" class="nav-link">Blog</a></li> -->
                </ul>
            </div>

            <div class="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                <a routerLink="/contact" class="btn btn-pink">Contact Us</a>
            </div>

            <!--offcanvas menu start-->
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasWithBackdrop">
                <div class="offcanvas-header d-flex align-items-center mt-4">
                    <a routerLink="index.html" class="d-flex align-items-center mb-md-0 text-decoration-none">
                        <img src="assets/img/logo-color.png" alt="logo" class="img-fluid ps-2" />
                    </a>
                    <button type="button" class="close-btn text-danger" data-bs-dismiss="offcanvas" aria-label="Close">
                        <i class="far fa-close"></i>
                    </button>
                </div>
                <div class="offcanvas-body">
                    <ul class="nav col-12 col-md-auto justify-content-center main-menu">
                        <li><a routerLink="" class="nav-link">Home</a></li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                Services
                            </a>
                            <div class="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                <div class="dropdown-grid rounded-custom width-half">
                                    <div class="dropdown-grid-item">
                                        <a routerLink="/services/mobile-development" class="dropdown-link px-0">
                                            <span class="me-2"><i class="fa fa-mobile-button"></i></span>
                                            <div class="drop-title">Mobile Development</div>
                                        </a>
                                        <a routerLink="/services/frontend-development" class="dropdown-link px-0">
                                            <span class="me-2"><i class="fab fa-html5"></i></span>
                                            <div class="drop-title">Frontend Development</div>
                                        </a>
                                        <a routerLink="/services/backend-development" class="dropdown-link px-0">
                                            <span class="me-2"><i class="devicon-graphql-plain"></i></span>
                                            <div class="drop-title">Backend Development</div>
                                        </a>
                                        <a routerLink="/services/custom-software" class="dropdown-link px-0">
                                            <span class="me-2"><i class="fa fa-laptop-code"></i></span>
                                            <div class="drop-title">Custom Software Development</div>
                                        </a>
                                        <!-- <a routerLink="/servies/team-extension" class="dropdown-link px-0">
                                            <span class="me-2"><i class="fad fa-poll-people"></i></span>
                                            <div class="drop-title">Team Extension</div>
                                        </a> -->
                                        <a routerLink="/services/training" class="dropdown-link px-0">
                                            <span class="me-2"><i class="fa fa-graduation-cap"></i></span>
                                            <div class="drop-title">Training</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">Technologies</a>
                            <div class="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                <div class="dropdown-grid rounded-custom width-full-3">
                                    <div class="dropdown-grid-item">
                                        <h6 class="drop-heading"><span class="me-2"><i
                                                    class="fa fa-mobile-button"></i></span> Mobile</h6>
                                        <a routerLink="/technologies/android-app-development"
                                            class="dropdown-link px-0">
                                            <span class="me-2"><i class="devicon-android-plain"></i></span>
                                            <div class="drop-title">Android</div>
                                        </a>
                                        <a routerLink="/technologies/ios-app-development" class="dropdown-link px-0">
                                            <span class="me-2"><i class="devicon-apple-plain"></i></span>
                                            <div class="drop-title">IOS</div>
                                        </a>
                                        <h6 class="drop-heading"><span class="me-2"><i class="fab fa-html5"></i></span>
                                            Frontend</h6>
                                        <a routerLink="/technologies/angular-app-development"
                                            class="dropdown-link px-0">
                                            <span class="me-2"><i class="devicon-angularjs-plain"></i></span>
                                            <div class="drop-title">Angular</div>
                                        </a>
                                        <a routerLink="/technologies/react-app-development" class="dropdown-link px-0">
                                            <span class="me-2"><i class="devicon-react-plain"></i></span>
                                            <div class="drop-title">React</div>
                                        </a>
                                        <a routerLink="/technologies/wordpress-app-development"
                                            class="dropdown-link px-0">
                                            <span class="me-2"><i class="devicon-wordpress-plain"></i></span>
                                            <div class="drop-title">Wordpress</div>
                                        </a>
                                    </div>
                                    <div class="dropdown-grid-item">
                                        <h6 class="drop-heading"><span class="me-2"><i
                                                    class="devicon-graphql-plain"></i></span> Backend</h6>
                                        <a routerLink="/technologies/scala-backend-development"
                                            class="dropdown-link px-0">
                                            <span class="me-2"><i class="devicon-scala-plain"></i></span>
                                            <div class="drop-title">Scala</div>
                                        </a>
                                        <a routerLink="/technologies/python-backend-development"
                                            class="dropdown-link px-0">
                                            <span class="me-2"><i class="devicon-python-plain"></i></span>
                                            <div class="drop-title">Python</div>
                                        </a>
                                        <a routerLink="/technologies/node-backend-development"
                                            class="dropdown-link px-0">
                                            <span class="me-2"><i class="devicon-nodejs-plain"></i></span>
                                            <div class="drop-title">Node.js</div>
                                        </a>
                                        <h6 class="drop-heading"><span class="me-2"><i
                                                    class="fa fa-mobile-iphone"></i></span> Cross-Platform</h6>
                                        <a routerLink="/technologies/flutter-app-development"
                                            class="dropdown-link px-0">
                                            <span class="me-2"><i class="devicon-flutter-plain"></i></span>
                                            <div class="drop-title">Flutter</div>
                                        </a>
                                        <a routerLink="/technologies/react-native-app-development"
                                            class="dropdown-link px-0">
                                            <span class="me-2"><i class="devicon-react-plain"></i></span>
                                            <div class="drop-title">React Native</div>
                                        </a>
                                    </div>
                                    <div class="dropdown-grid-item last-item bg-light radius-right-side">
                                        <a routerLink="#"><img src="assets/img/feature-img3.jpg" alt="add"
                                                class="img-fluid rounded-custom" /></a>
                                    </div>
                                </div>
                            </div>
                        </li> -->
                        <li><a routerLink="/about" class="nav-link">About</a></li>
                        <li><a routerLink="/portfolio" class="nav-link">Portfolio</a></li>
                        <li><a routerLink="/careers" class="nav-link">Careers</a></li>
                        <li><a routerLink="/blog" class="nav-link">Blog</a></li>
                    </ul>
                    <div class="action-btns mt-4 ps-3">
                        <a routerLink="/contact" class="btn btn-primary">Contact Us</a>
                    </div>
                </div>
            </div>
            <!--offcanvas menu end-->
        </div>
    </nav>
</header>
<!--header end-->
