<!--hero section start-->
<section class="hero-section ptb-120 bg-gradient text-white" style="background:
      url('assets/img/app-two-mockup-bg.png') no-repeat
      center center / cover">
    <div class="container">
        <div class="row justify-content-xl-between align-items-center">
            <div class="col-lg-5 col-xl-5 col-xl-5 col-md-10">
                <div class="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                    <h5 class="text-pink">Build your best quality app</h5>
                    <h1 class="fw-bold display-5">Develop Your App with Quiety</h1>
                    <p class="lead">
                        Completely incubate client-centric data for robust infrastructures.
                        Quickly extend revolutionary accurate strategic theme areas without
                        cooperative.
                    </p>
                    <div class="action-btns mt-5">
                        <a href="about-us.html" class="btn btn-outline-light">Explore More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-8 mt-5">
                <div class="hero-app-img position-relative text-center">
                    <img src="assets/img/app-two-phone.png" alt="app screen" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>
</section>
<!--hero section end-->

<!-- app two feature start -->
<section class="app-two-feature ptb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="section-heading text-center">
                    <h2>Explore Premium Features</h2>
                    <p>
                        A bit of how's your father he nicked it charles daft plast lavator.
                    </p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-xl-10">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12">
                        <div class="app-two-single-feature d-md-flex align-items-start mb-30">
                            <div class="app-two-single-feature-icon box-one me-3 mb-4 mb-md-0">
                                <i class="far fa-file-edit"></i>
                            </div>
                            <div class="app-two-single-feature-content">
                                <h3 class="h5">Easy to Customized</h3>
                                <p>
                                    Authoritatively productize orthogonal networks rather than
                                    state of the art interfaces.
                                </p>
                                <a href="service-single.html" class="link-with-icon text-decoration-none">View Details
                                    <i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12">
                        <div class="app-two-single-feature d-md-flex align-items-start mb-30">
                            <div class="app-two-single-feature-icon box-two me-3 mb-4 mb-md-0">
                                <i class="far fa-bell"></i>
                            </div>
                            <div class="app-two-single-feature-content">
                                <h3 class="h5">Mobile App</h3>
                                <p>
                                    Enthusiastically envisioneer integrated catalysts for change
                                    before fully researched vortals.
                                </p>
                                <a href="service-single.html" class="link-with-icon text-decoration-none">View Details
                                    <i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12">
                        <div class="app-two-single-feature d-md-flex align-items-start mb-30">
                            <div class="app-two-single-feature-icon box-three me-3 mb-4 mb-md-0">
                                <i class="far fa-pen-nib"></i>
                            </div>
                            <div class="app-two-single-feature-content">
                                <h3 class="h5">Expand your reach</h3>
                                <p>
                                    Authoritatively syndicate focused ideas whereas revolutionary
                                    outsourcing.
                                </p>
                                <a href="service-single.html" class="link-with-icon text-decoration-none">View Details
                                    <i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12">
                        <div class="app-two-single-feature d-md-flex align-items-start mb-30">
                            <div class="app-two-single-feature-icon box-four me-3 mb-4 mb-md-0">
                                <i class="far fa-cloud-download-alt"></i>
                            </div>
                            <div class="app-two-single-feature-content">
                                <h3 class="h5">Free Download</h3>
                                <p>
                                    Rapidiously reintermediate plug-and-play potentialities via
                                    cross-unit e-markets.
                                </p>
                                <a href="service-single.html" class="link-with-icon text-decoration-none">View Details
                                    <i class="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- app two feature end -->

<!-- app two feature two start -->
<section class="app-two-feature-two pt-60 pb-120">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 col-xl-6 col-md-12">
                <div class="app-two-feature-two-img">
                    <img src="assets/img/app-two-feature-phone-with-shape.png" alt="feature image" class="img-fluid" />
                </div>
            </div>
            <div class="col-xl-5 col-lg-5">
                <div class="app-two-feature-two-right">
                    <div class="feature-content-wrap">
                        <h4 class="h6">Best Features</h4>
                        <h2>Boost up your Phone in just one click</h2>
                        <p>
                            Completely evolve orthogonal sources via cooperative data. Dynamically promote high
                            standards in resources after timely catalysts for alignments and visionary quality vectors.
                        </p>
                    </div>
                    <div class="app-two-feature-two-content">
                        <ul class="list-unstyled d-flex flex-wrap list-two-col mt-4">
                            <li class="py-1">
                                <i class="fad fa-check-circle me-2"></i>Based on Bootstrap Grid
                            </li>
                            <li class="py-1">
                                <i class="fad fa-check-circle me-2"></i>Online Learning
                            </li>
                            <li class="py-1">
                                <i class="fad fa-check-circle me-2"></i>Track your spending
                            </li>
                            <li class="py-1">
                                <i class="fad fa-check-circle me-2"></i>Unlimited Download
                            </li>
                            <li class="py-1">
                                <i class="fad fa-check-circle me-2"></i>Responisve Design
                            </li>
                            <li class="py-1">
                                <i class="fad fa-check-circle me-2"></i> Always Be in Touch
                            </li>
                        </ul>
                        <div class="action-btns mt-5">
                            <a href="about-us.html" class="btn app-two-btn">Learn About More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- app two feature two end -->

<!-- app two feature three start -->
<section class="app-two-feature-three bg-light ptb-120">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-5 col-xl-5 col-sm-12">
                <div class="app-two-feature-three-left">
                    <div class="app-content-feature-wrap">
                        <h2>We provide Quality works for developed App</h2>
                        <p>
                            Car boot absolutely bladdered posh burke the wireless mush some
                            dodgy chav you mug. Energistically conceptualize scalable quality
                            vectors through wireless channels. .
                        </p>
                    </div>
                    <div class="accordion faq-accordion mt-5" id="accordionExample">
                        <div class="accordion-item active border-0">
                            <h5 class="accordion-header" id="faq-1">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapse-1" aria-expanded="true">
                                    <i class="far fa-bell pe-3"></i> How does back pricing work?
                                </button>
                            </h5>
                            <div id="collapse-1" class="accordioncollapse collapse show" aria-labelledby="faq-1"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Dramatically formulate cross-unit products with web-enabled
                                    action items. Quickly maximize extensible methods of
                                    empowerment.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item border-0">
                            <h5 class="accordion-header" id="faq-2">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapse-2" aria-expanded="false">
                                    <i class="far fa-command pe-3"></i>
                                    Can you show me an example?
                                </button>
                            </h5>
                            <div id="collapse-2" class="accordion-collapse collapse" aria-labelledby="faq-2"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Continually innovate technically sound internal or "organic"
                                    sources with market positioning content. Completely drive
                                    optimal intellectual.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item border-0">
                            <h5 class="accordion-header" id="faq-3">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapse-3" aria-expanded="false">
                                    <i class="fas fa-link pe-3"></i>
                                    How do I processing I need?
                                </button>
                            </h5>
                            <div id="collapse-3" class="accordion-collapse collapse" aria-labelledby="faq-3"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Phosfluorescently deliver cooperative testing procedures after
                                    integrated communities. Dramatically simplify
                                    resource-leveling models with unique.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-6 col-sm-12">
                <div class="app-two-feature-three-img position-relative text-center mt-5 mt-lg-0">
                    <div class="feature-three-shape">
                        <img src="assets/img/app-two-feature-blob-shape.png" alt="app screen"
                            class="feature-three-blob img-fluid" />
                    </div>
                    <img src="assets/img/app-two-feature-phone-without-shape.png" alt="app screen"
                        class="position-relative z-5" />
                    <div class="img-peice d-none d-lg-block">
                        <img src="assets/img/app-two-file.png" alt="app screen"
                            class="img-one position-absolute custom-shadow" />
                        <img src="assets/img/app-two-video-shape.png" alt="app screen"
                            class="img-two position-absolute custom-shadow" />
                        <img src="assets/img/app-two-chart.png" alt="app screen"
                            class="img-three position-absolute custom-shadow" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- app two feature three end -->

<!-- app two feature three start -->
<section class="app-two-cta ptb-120 text-white bg-gradient" style="background-image:
      url('assets/img/hero-dot-bg.png')">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 col-xl-6">
                <div class="app-two-mockup position-relative text-center pe-5">
                    <img src="assets/img/app-two-mockup.png" alt="" class="img-fluid position-relative z-5" />
                </div>
            </div>
            <div class="col-lg-6 col-xl-6 col-md-10">
                <div class="app-two-cta-right px-md-0 pt-5 pt-md-0">
                    <div class="section-heading text-white">
                        <h2 class="text-white">
                            Powerful Solutions for Your Business Needs
                        </h2>
                        <p>
                            Quickly incubate functional channels with multidisciplinary
                            architectures authoritatively fabricate.
                        </p>
                    </div>
                    <div class="cta-count">
                        <ul class="list-unstyled d-flex">
                            <li class="me-4">
                                <h3 class="text-white mb-0">72k+</h3>
                                <span>Happy users</span>
                            </li>
                            <li class="me-4">
                                <h3 class="text-white mb-0">16</h3>
                                <span>Good reviews</span>
                            </li>
                            <li class="me-4">
                                <h3 class="text-white mb-0">28k+</h3>
                                <span>App download</span>
                            </li>
                        </ul>
                    </div>
                    <div class="action-btns mt-5">
                        <a href="contact-us.html" class="btn btn-outline-light">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- app two feature three end -->

<!-- app two customer review start -->
<section class="cutomer-review-app-two ptb-120">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-12">
          <div class="mb-4 mb-sm-5">
            <h2>Our Happy Clients say about us.</h2>
            <p class="m-0">
              Oxford hunky dory super so I said bender arse over blog.so I said
              bender arse over blog.
            </p>
          </div>
        </div>
        <div class="col-lg-5 col-12 text-end d-none d-lg-block">
          <div class="appTwoReviewSwiper-Controller ms-md-auto position-relative">
            <span class="swiper-button-next"></span>
            <span class="swiper-button-prev"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <swiper [config]="config" class="appTwoReviewSwiper">
            <ng-template swiperSlide>
              <div
                class="single-review-slider p-lg-5 p-4 bg-white rounded-custom border my-lg-4"
              >
                <ul class="review-rate mb-1 list-unstyled list-inline">
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                </ul>
                <h5 class="h6">5 review average in our app</h5>
                <p>
                  Blinding shot excuse my french willia david tosser jeffrey
                  bubble and sque bleeder mush.
                </p>
                <div class="d-flex align-items-center">
                  <img
                    src="assets/img/testimonial/app-testimonial-1.png"
                    class="img-fluid me-3 rounded-circle"
                    width="50"
                    alt="user"
                  />
                  <div class="author-info">
                    <h6 class="mb-0">Chauffina Carr</h6>
                    <small>Founder</small>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template swiperSlide>
              <div
                class="single-review-slider p-lg-5 p-4 bg-white rounded-custom border my-lg-4"
              >
                <ul class="review-rate mb-1 list-unstyled list-inline">
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                </ul>
                <h5 class="h6">35+ minutes per session</h5>
                <p>
                  Blinding shot excuse my french willia david tosser jeffrey
                  bubble and sque bleeder mush.
                </p>
                <div class="d-flex align-items-center">
                  <img
                    src="assets/img/testimonial/app-testimonial-2.png"
                    class="img-fluid me-3 rounded-circle"
                    width="50"
                    alt="user"
                  />
                  <div class="author-info">
                    <h6 class="mb-0">Hugh Saturation</h6>
                    <small>UX Designer</small>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template swiperSlide>
              <div
                class="single-review-slider p-lg-5 p-4 bg-white rounded-custom border my-lg-4"
              >
                <ul class="review-rate mb-1 list-unstyled list-inline">
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                  <li class="list-inline-item">
                    <i class="fas fa-star text-pink"></i>
                  </li>
                </ul>
                <h5 class="h6">$0.00 everything is FREE</h5>
                <p>
                  Blinding shot excuse my french willia david tosser jeffrey
                  bubble and sque bleeder mush.
                </p>
                <div class="d-flex align-items-center">
                  <img
                    src="assets/img/testimonial/app-testimonial-3.png"
                    class="img-fluid me-3 rounded-circle"
                    width="50"
                    alt="user"
                  />
                  <div class="author-info">
                    <h6 class="mb-0">Richard Tea</h6>
                    <small>Manager</small>
                  </div>
                </div>
              </div>
            </ng-template>
          </swiper>
        </div>
      </div>
    </div>
  </section>
<!-- app two customer review end -->

<!-- app two download cta start -->
<section class="app-two-download-cta bg-gradient ptb-120" style="background: url('assets/img/app-download-cta.png') no-repeat
        center center / cover">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-xl-6 col-md-10">
                <div class="section-heading text-center">
                    <h2 class="text-white">Your App will be published on major platforms</h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-8 col-xl-7 col-12">
                <div class="action-btns mt-3">
                    <ul class="list-unstyled text-center">
                        <li class="d-inline-block me-2 mb-lg-0">
                            <a class="d-flex align-items-center text-decoration-none rounded"><i
                                    class="fab fa-apple pe-2"></i>
                                <span class="text-black">Deploy the App on <span>App Store</span></span></a>
                        </li>
                        <li class="d-inline-block me-2 mb-lg-0">
                            <a class="d-flex align-items-center text-decoration-none rounded" href="#"><i
                                    class="fab fa-google-play pe-2"></i>
                                <span class="text-black">Deploy the App on <span>Google Play</span></span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- app two download cta end -->
