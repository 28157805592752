<!--hero section start-->
<!-- <section class="hero-section ptb-120 position-relative bg-gradient"
    style="background: url('assets/img/shape/color-particles-2.svg')no-repeat center top">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-xl-8 col-lg-10 mb-5">
                <div class="hero-content-wrap">
                    <h1 class="fw-bold display-5" data-aos="fade-up">Single Dashboard for All your Business Needs</h1>
                    <p class="lead" data-aos="fade-up" data-aos-delay="50">Uniquely network business experiences for
                        resource sucking solutions. Dynamically re-engineer cooperative networks via cross-media
                        expertise.</p>
                    <div class="action-btns text-center pt-4" data-aos="fade-up" data-aos-delay="100">
                        <a href="request-demo.html" class="btn btn-primary me-lg-3 me-sm-3">Start Free Trial</a>
                        <a href="contact-us.html" class="btn btn-outline-primary">Talk to Sales</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <div class="position-relative" data-aos="fade-up" data-aos-delay="200">
                    <ul class="position-absolute animate-element parallax-element widget-img-wrap z-2">
                        <li class="layer" data-depth="0.04">
                            <img src="assets/img/screen/widget-3.png" alt="widget-img"
                                class="img-fluid widget-img-1 position-absolute shadow-lg rounded-custom">
                        </li>
                        <li class="layer" data-depth="0.02">
                            <img src="assets/img/screen/widget-4.png" alt="widget-img"
                                class="img-fluid widget-img-3 position-absolute shadow-lg rounded-custom">
                        </li>
                    </ul>
                    <img src="assets/img/dashboard-img.png" alt="dashboard image"
                        class="img-fluid position-relative rounded-custom mt-lg-5">
                </div>
            </div>
        </div>
    </div>
    <div class="bg-gradient position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z--1 py-5"></div>
</section> -->
<!--hero section end-->
<section
  class="hero-section ptb-120 position-relative overflow-hidden"
  style="
    background: url('./assets/img/shape/color-particles-2.svg') no-repeat center
      top;
  "
>
  <div class="container">
    <div class="row justify-content-center text-center">
      <div class="col-xl-8 col-lg-10 mb-5">
        <div class="hero-content-wrap">
          <h1 class="fw-bold display-5" data-aos="fade-up">
            Single Dashboard for All your Business Needs
          </h1>
          <p class="lead" data-aos="fade-up" data-aos-delay="50">
            Uniquely network business experiences for resource sucking
            solutions. Dynamically re-engineer cooperative networks via
            cross-media expertise.
          </p>
          <div
            class="action-btns text-center pt-4"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <a routerLink="/request-for-demo" class="btn btn-primary me-3"
              >Start a Free Trial</a
            >
            <a routerLink="/contact" class="btn btn-outline-primary"
              >Talk to Sales</a
            >
          </div>
        </div>
      </div>

      <div class="col-lg-9">
        <div class="position-relative" data-aos="fade-up" data-aos-delay="200">
          <ul
            class="position-absolute animate-element parallax-element widget-img-wrap z-2"
          >
            <li class="layer" data-depth="0.04">
              <img
                src="./assets/img/screen/widget-3.png"
                alt="widget-img"
                class="img-fluid widget-img-1 position-absolute shadow-lg rounded-custom"
              />
            </li>
            <li class="layer" data-depth="0.02">
              <img
                src="./assets/img/screen/widget-4.png"
                alt="widget-img"
                class="img-fluid widget-img-3 position-absolute shadow-lg rounded-custom"
              />
            </li>
          </ul>
          <img
            src="./assets/img/dashboard-img.png"
            alt=""
            class="img-fluid position-relative rounded-custom mt-5"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    class="bg-dark position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z--1 py-5"
  ></div>
</section>
<section class="customer-review pb-120 bg-dark">
    <div class="container">
      <div class="row">
        <div class="section-heading text-center" data-aos="fade-up">
          <h2 class="fw-medium h4">Rated 5 out of 5 stars by our customers</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-4">
          <div
            class="review-info-wrap text-center rounded-custom d-flex flex-column h-100 p-5"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <img
              src="./assets/img/fb-logo-w.svg"
              width="130"
              alt="facebook"
              class="img-fluid m-auto"
            />
            <div class="review-info-content mt-2">
              <p class="mb-4">
                4.9 out of 5 stars maintainable disseminate parallel team
                effective standards communities.
              </p>
            </div>
            <a
              routerLink="/about"
              class="link-with-icon p-0 mt-auto text-decoration-none text-pink"
              >Read Reviews <i class="far fa-arrow-right"></i
            ></a>
          </div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div
            class="review-info-wrap text-center rounded-custom d-flex flex-column h-100 p-5"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img
              src="./assets/img/g-logo-w.svg"
              width="130"
              alt="google"
              class="img-fluid m-auto"
            />
            <div class="review-info-content mt-2">
              <p class="mb-4">
                2k+ five star reviews excellent convergence without professional
                methods of empowerment.
              </p>
            </div>
            <a
              routerLink="/about"
              class="link-with-icon p-0 mt-auto text-decoration-none text-pink"
              >Read Reviews <i class="far fa-arrow-right"></i
            ></a>
          </div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div
            class="review-info-wrap text-center rounded-custom d-flex flex-column h-100 p-5"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <img
              src="./assets/img/li-logo-w.svg"
              width="130"
              alt="linkedIn"
              class="img-fluid m-auto"
            />
            <div class="review-info-content mt-2">
              <p class="mb-4">
                4.9 out of 5 stars maintainable disseminate parallel team
                effective standards communities.
              </p>
            </div>
            <a
              routerLink="/about"
              class="link-with-icon p-0 mt-auto text-decoration-none text-pink"
              >Read Reviews <i class="far fa-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </section>


<!--feature section start-->
<section class="image-feature ptb-120">
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-lg-5 col-12">
                <div class="feature-img-content">
                    <div class="section-heading" data-aos="fade-up">
                        <h2>Browse our world class features</h2>
                        <p>Energistically underwhelm progressive metrics via value-added impact magnetic world-class
                            paradigms portals. Pontificate
                            reliable metrics with enabled maintain clicks-and-mortar manufactured.</p>
                    </div>
                    <ul class="list-unstyled d-flex flex-wrap list-two-col mb-0" data-aos="fade-up" data-aos-delay="50">
                        <li>
                            <div class="icon-box">
                                <i class="fas fa-users text-white bg-primary rounded"></i>
                            </div>
                            <h3 class="h5">Customer Aanalysis</h3>
                            <p>Objectively productize exceptional via customized.</p>
                        </li>
                        <li>
                            <div class="icon-box">
                                <i class="fas fa-fingerprint text-white bg-danger rounded"></i>
                            </div>
                            <h3 class="h5">Real Time Metrics</h3>
                            <p>Interactively integrate extensible users maximizing resource. </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="feature-img-holder p-lg-5 pt-3">
                    <div class="p-lg-5 p-3 bg-danger-soft position-relative rounded-custom d-block feature-img-wrap">
                        <div class="z-10 position-relative">
                            <img src="assets/img/screen/widget-5.png" class="img-fluid rounded-custom position-relative"
                                alt="feature-image" data-aos="fade-up" data-aos-delay="50">
                            <img src="assets/img/screen/widget-3.png"
                                class="img-fluid rounded-custom shadow position-absolute bottom--100 right--100 hide-medium"
                                alt="feature-image" data-aos="fade-up" data-aos-delay="100">
                        </div>
                        <div class="position-absolute bg-dark-soft z--1 dot-mask dm-size-12 dm-wh-250 top-left"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="image-feature pt-60 pb-120">
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-lg-5 col-12 order-lg-1">
                <div class="feature-img-content">
                    <div class="section-heading" data-aos="fade-up">
                        <h2>Measure your efficiency for business</h2>
                        <p>Reliable metrics with enabled infomediaries. Holisticly maintain clicks-and-mortar
                            manufactured products empower viral customer service through resource
                            supply pandemic collaboration. </p>
                    </div>
                    <ul class="list-unstyled d-flex flex-wrap list-two-col mb-0" data-aos="fade-up" data-aos-delay="50">
                        <li>
                            <div class="icon-box">
                                <i class="fas fa-dollar-sign text-white bg-success rounded"></i>
                            </div>
                            <h3 class="h5">Finance Models</h3>
                            <p>Objectively error-free develop intuitive catalysts for change.</p>
                        </li>
                        <li>
                            <div class="icon-box">
                                <i class="fas fa-headset text-white bg-dark rounded"></i>
                            </div>
                            <h3 class="h5">Daily Consultancy</h3>
                            <p>Compellingly initiate distinctive principle-centered sources.</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-12 order-lg-0">
                <div class="feature-img-holder p-lg-5 pt-3">
                    <div class="bg-primary-soft p-lg-5 p-3 rounded-custom position-relative d-block feature-img-wrap">
                        <div class="z-10 position-relative">
                            <img src="assets/img/screen/widget-8.png" class="img-fluid rounded-custom position-relative"
                                alt="feature-image" data-aos="fade-up" data-aos-delay="50">
                            <img src="assets/img/screen/widget-6.png"
                                class="img-fluid rounded-custom shadow position-absolute top--100 left--100 hide-medium"
                                alt="feature-image" data-aos="fade-up" data-aos-delay="100">
                        </div>
                        <div class="position-absolute bg-dark-soft z--1 dot-mask dm-size-12 dm-wh-250 bottom-right">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="image-feature pt-60 pb-120">
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-lg-5 col-12">
                <div class="feature-img-content">
                    <div class="section-heading" data-aos="fade-up">
                        <h2>We create software & digital product</h2>
                        <p>Credibly deliver out-of-the-box benefits through just in time best practices. Globally
                            orchestrate holistic communities without visualize performance based premium alignments.</p>
                    </div>
                    <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5" data-aos="fade-up"
                        data-aos-delay="100">
                        <li>
                            <div class="icon-box">
                                <i class="fas fa-briefcase text-white bg-pinkrounded"></i>
                            </div>
                            <h3 class="h5">Fast Services</h3>
                            <p>Rapidiously exploit robust solutions without alignments.</p>
                        </li>
                        <li>
                            <div class="icon-box">
                                <i class="fas fa-truck text-white bg-info rounded"></i>
                            </div>
                            <h3 class="h5">Free Shipping</h3>
                            <p>Interactively integrate whereas extensible users resource. </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="feature-img-holder p-lg-5 pb-3">
                    <div class="bg-danger-soft p-lg-5 p-3 rounded-custom position-relative d-block feature-img-wrap">
                        <div class="z-10 position-relative">
                            <img src="assets/img/screen/widget-9.png" class="img-fluid rounded-custom position-relative"
                                alt="feature-image" data-aos="fade-up" data-aos-delay="50">
                            <img src="assets/img/screen/widget-10.png"
                                class="img-fluid rounded-custom shadow position-absolute top--100 right--100 hide-medium"
                                alt="feature-image" data-aos="fade-up" data-aos-delay="100">
                        </div>
                        <div class="position-absolute bg-dark-soft z--1 dot-mask dm-size-12 dm-wh-250 bottom-left">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature section end-->

<!--feature service grid section start-->
<section class="feature-section ptb-120  bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="section-heading text-center" data-aos="fade-up">
                    <h2>Best Services Grow Your Business Value</h2>
                    <p>Globally actualize cost effective with resource maximizing leadership skills.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="feature-grid">
                    <div class="feature-card bg-white   shadow-sm highlight-card rounded-custom p-5" data-aos="fade-up"
                        data-aos-delay="50">
                        <div class="icon-box d-inline-block rounded-circle bg-primary-soft mb-32">
                            <i class="fal fa-analytics icon-sm text-primary"></i>
                        </div>
                        <div class="feature-content">
                            <h3 class="h5">Advanced analytics</h3>
                            <p>Synergistically pursue accurate initiatives without economically sound
                                imperatives.</p>
                            <p> Professionally architect unique process improvements via optimal.</p>
                            <h6 class="mt-4">Included with...</h6>
                            <ul class="list-unstyled mb-0">
                                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>High-converting
                                </li>
                                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Personal branding
                                </li>
                                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Modernized
                                    prospecting</li>
                                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Clean and modern
                                </li>
                                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Showcasing success
                                </li>
                                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Bootstrap latest
                                    version</li>
                            </ul>
                        </div>
                        <a href="service-single.html" class="link-with-icon text-decoration-none mt-3">View Details <i
                                class="far fa-arrow-right"></i></a>
                    </div>
                    <div class="feature-card bg-white   shadow-sm rounded-custom p-5" data-aos="fade-up"
                        data-aos-delay="50">
                        <div class="icon-box d-inline-block rounded-circle bg-success-soft mb-32">
                            <i class="fal fa-file-chart-line icon-sm text-success"></i>
                        </div>
                        <div class="feature-content">
                            <h3 class="h5">Automated Reports</h3>
                            <p class="mb-0">Synergistically pursue accurate initiatives without economically
                                imperatives.</p>
                        </div>
                        <a href="service-single.html" class="link-with-icon text-decoration-none mt-3">View Details <i
                                class="far fa-arrow-right"></i></a>
                    </div>
                    <div class="feature-card bg-white   shadow-sm rounded-custom p-5" data-aos="fade-up"
                        data-aos-delay="50">
                        <div class="icon-box d-inline-block rounded-circle bg-danger-soft mb-32">
                            <i class="fal fa-user-friends icon-sm text-danger"></i>
                        </div>
                        <div class="feature-content">
                            <h3 class="h5">User Journey Flow</h3>
                            <p class="mb-0">Quickly productize prospective value before collaborative benefits.</p>
                        </div>
                        <a href="service-single.html" class="link-with-icon text-decoration-none mt-3">View Details <i
                                class="far fa-arrow-right"></i></a>
                    </div>
                    <div class="feature-card bg-white   shadow-sm rounded-custom p-5" data-aos="fade-up"
                        data-aos-delay="50">
                        <div class="icon-box d-inline-block rounded-circle bg-dark-soft mb-32">
                            <i class="fal fa-spell-check icon-sm text-dark"></i>
                        </div>
                        <div class="feature-content">
                            <h3 class="h5">Compare A/B Testing</h3>
                            <p class="mb-0">Credibly disintermediate functional processes for team driven action.</p>
                        </div>
                        <a href="service-single.html" class="link-with-icon text-decoration-none mt-3">View Details <i
                                class="far fa-arrow-right"></i></a>
                    </div>
                    <div class="feature-card bg-white   shadow-sm rounded-custom p-5" data-aos="fade-up"
                        data-aos-delay="50">
                        <div class="icon-box d-inline-block rounded-circle bg-warning-soft mb-32">
                            <i class="fal fa-cog icon-sm text-pink"></i>
                        </div>
                        <div class="feature-content">
                            <h3 class="h5">Easy Customization</h3>
                            <p class="mb-0">Dynamically develop ubiquitous opportunities whereas relationships.</p>
                        </div>
                        <a href="service-single.html" class="link-with-icon text-decoration-none mt-3">View Details <i
                                class="far fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature service grid section end-->

<!--our work process start-->
<section class="work-process ptb-120 bg-dark text-white">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-6">
                <div class="section-heading text-center" data-aos="fade-up">
                    <h2>We Follow Our Work Process</h2>
                    <p>Conveniently mesh cooperative services via magnetic outsourcing. Dynamically grow value whereas
                        accurate e-commerce parallel quality vectors.</p>
                </div>
            </div>
        </div>
        <div class="row d-flex align-items-center">
            <div class="col-md-6 col-lg-3">
                <div class="process-card-two text-center px-4 py-lg-5 py-4 rounded-custom mt-3" data-aos="fade-up"
                    data-aos-delay="50">
                    <div class="process-icon border border-light bg-custom-light rounded-custom p-3">
                        <i class="fad fa-folder-tree fa-2x"></i>
                    </div>
                    <h3 class="h5">Research</h3>
                    <p class="mb-0">Quickly administrate customer service rather than transparent.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="process-card-two text-center px-4 py-lg-5 py-4 rounded-custom mt-3" data-aos="fade-up"
                    data-aos-delay="100">
                    <div class="process-icon border border-light bg-custom-light rounded-custom p-3">
                        <i class="fad fa-bezier-curve fa-2x"></i>
                    </div>
                    <h3 class="h5">Designing</h3>
                    <p class="mb-0">Dramatically B2C solutions whereas backend imperatives.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="process-card-two text-center px-4 py-lg-5 py-4 rounded-custom mt-3" data-aos="fade-up"
                    data-aos-delay="150">
                    <div class="process-icon border border-light bg-custom-light rounded-custom p-3">
                        <i class="fad fa-layer-group fa-2x"></i>
                    </div>
                    <h3 class="h5">Building</h3>
                    <p class="mb-0">Monotonectally deploy team driven vis-a-vis goal-oriented.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="process-card-two text-center px-4 py-lg-5 py-4 rounded-custom mt-3" data-aos="fade-up"
                    data-aos-delay="200">
                    <div class="process-icon border border-light bg-custom-light rounded-custom p-3">
                        <i class="fad fa-truck fa-2x"></i>
                    </div>
                    <h3 class="h5">Deliver</h3>
                    <p class="mb-0">Dramatically web-enabled portals for core competencies.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--our work process end-->

<!--customer review tab section start-->
<section class="customer-review-tab ptb-120  bg-light position-relative z-2">
    <div class="container">
        <div class="row justify-content-center align-content-center">
            <div class="col-md-10 col-lg-6">
                <div class="section-heading text-center" data-aos="fade-up">
                    <h4 class="h5  text-primary">Testimonial</h4>
                    <h2>What They Say About Us</h2>
                    <p>Uniquely promote adaptive quality vectors rather than stand-alone e-markets. pontificate
                        alternative architectures whereas iterate.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="tab-content" id="testimonial-tabContent" data-aos="fade-up" data-aos-delay="100">
                    <div class="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-lg-6 col-md-6">
                                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                                    <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                                        class="img-fluid mb-32">
                                    <div class="blockquote-title-review mb-4">
                                        <h3 class="mb-0 h4 fw-semi-bold">The Best Template You Got to Have it!</h3>
                                        <ul class="review-rate mb-0 list-unstyled list-inline">
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                        </ul>
                                    </div>

                                    <blockquote class="blockquote">
                                        <p>Globally network long-term high-impact schemas vis-a-vis distinctive
                                            e-commerce
                                            cross-media infrastructures rather than ethical sticky alignments rather
                                            than global. Plagiarize technically sound total linkage for leveraged value
                                            media web-readiness and premium processes.</p>
                                    </blockquote>
                                    <div class="author-info mt-4">
                                        <h6 class="mb-0">Joe Richard</h6>
                                        <span>Visual Designer</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="author-img-wrap pt-5 ps-5">
                                    <div class="testimonial-video-wrapper position-relative">
                                        <img src="assets/img/testimonial/t-1.jpg"
                                            class="img-fluid rounded-custom shadow-lg" alt="testimonial author">
                                        <div class="customer-info text-white d-flex align-items-center">
                                            <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                                                class="video-icon popup-youtube text-decoration-none"><i
                                                    class="fas fa-play"></i> <span class="text-white ms-2 small"> Watch
                                                    Video</span></a>
                                        </div>
                                        <div
                                            class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="testimonial-tab-2" role="tabpanel">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-lg-6 col-md-6">
                                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                                    <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                                        class="img-fluid mb-32">
                                    <div class="blockquote-title-review mb-4">
                                        <h3 class="mb-0 h4 fw-semi-bold">Embarrassed by the First Version.</h3>
                                        <ul class="review-rate mb-0 list-unstyled list-inline">
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                        </ul>
                                    </div>
                                    <blockquote class="blockquote">
                                        <p>Energistically streamline robust architectures whereas distributed
                                            mindshare. Intrinsicly leverage other's backend growth strategies
                                            through 24/365 products. Conveniently pursue revolutionary communities for
                                            compelling process improvements. </p>
                                    </blockquote>
                                    <div class="author-info mt-4">
                                        <h6 class="mb-0">Rupan Oberoi</h6>
                                        <span>Web Designer</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="author-img-wrap pt-5 ps-5">
                                    <div class="testimonial-video-wrapper position-relative">
                                        <img src="assets/img/testimonial/t-2.jpg"
                                            class="img-fluid rounded-custom shadow-lg" alt="testimonial author">
                                        <div class="customer-info text-white d-flex align-items-center">
                                            <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                                                class="video-icon popup-youtube text-decoration-none"><i
                                                    class="fas fa-play"></i> <span class="text-white ms-2 small"> Watch
                                                    Video</span></a>
                                        </div>
                                        <div
                                            class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="testimonial-tab-3" role="tabpanel">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-lg-6 col-md-6">
                                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                                    <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                                        class="img-fluid mb-32">
                                    <div class="blockquote-title-review mb-4">
                                        <h3 class="mb-0 h4 fw-semi-bold">Amazing Quiety template!</h3>
                                        <ul class="review-rate mb-0 list-unstyled list-inline">
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                        </ul>
                                    </div>
                                    <blockquote class="blockquote">
                                        <p>
                                            Appropriately negotiate interactive niches rather orchestrate scalable
                                            benefits whereas flexible systems. Objectively grow quality outsourcing
                                            without vertical methods of empowerment. Assertively negotiate just in time
                                            innovation after client-centered thinking.
                                        </p>
                                    </blockquote>
                                    <div class="author-info mt-4">
                                        <h6 class="mb-0">Jon Doe</h6>
                                        <span>Software Engineer</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="author-img-wrap pt-5 ps-5">
                                    <div class="testimonial-video-wrapper position-relative">
                                        <img src="assets/img/testimonial/t-3.jpg"
                                            class="img-fluid rounded-custom shadow-lg" alt="testimonial author">
                                        <div class="customer-info text-white d-flex align-items-center">
                                            <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                                                class="video-icon popup-youtube text-decoration-none"><i
                                                    class="fas fa-play"></i> <span class="text-white ms-2 small"> Watch
                                                    Video</span></a>
                                        </div>
                                        <div
                                            class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="testimonial-tab-4" role="tabpanel">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-lg-6 col-md-6">
                                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                                    <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                                        class="img-fluid mb-32">
                                    <div class="blockquote-title-review mb-4">
                                        <h3 class="mb-0 h4 fw-semi-bold">Best Template for SAAS Company!</h3>
                                        <ul class="review-rate mb-0 list-unstyled list-inline">
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                        </ul>
                                    </div>
                                    <blockquote class="blockquote">
                                        <p>Competently repurpose cost effective strategic theme areas and customer
                                            directed meta-services. Objectively orchestrate orthogonal initiatives
                                            after enterprise-wide bandwidth. Dynamically generate extensive through
                                            cooperative channels time partnerships. </p>
                                    </blockquote>
                                    <div class="author-info mt-4">
                                        <h6 class="mb-0">Hanry Luice</h6>
                                        <span>App Developer</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="author-img-wrap pt-5 ps-5">
                                    <div class="testimonial-video-wrapper position-relative">
                                        <img src="assets/img/testimonial/t-4.jpg"
                                            class="img-fluid rounded-custom shadow-lg" alt="testimonial author">
                                        <div class="customer-info text-white d-flex align-items-center">
                                            <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                                                class="video-icon popup-youtube text-decoration-none"><i
                                                    class="fas fa-play"></i> <span class="text-white ms-2 small"> Watch
                                                    Video</span></a>
                                        </div>
                                        <div
                                            class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="testimonial-tab-5" role="tabpanel">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-lg-6 col-md-6">
                                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                                    <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                                        class="img-fluid mb-32">
                                    <div class="blockquote-title-review mb-4">
                                        <h3 class="mb-0 h4 fw-semi-bold">It is Undeniably Good!</h3>
                                        <ul class="review-rate mb-0 list-unstyled list-inline">
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                            <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                                            </li>
                                        </ul>
                                    </div>
                                    <blockquote class="blockquote">
                                        <p>Appropriately disintermediate one-to-one vortals through cross functional
                                            infomediaries. Collaboratively pursue multidisciplinary systems through
                                            stand-alone architectures. Progressively transition covalent architectures
                                            whereas vertical applications procrastinate professional.</p>
                                    </blockquote>
                                    <div class="author-info mt-4">
                                        <h6 class="mb-0">Ami Nijai</h6>
                                        <span>Customer Support</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <div class="author-img-wrap pt-5 ps-5">
                                    <div class="testimonial-video-wrapper position-relative">
                                        <img src="assets/img/testimonial/t-5.jpg"
                                            class="img-fluid rounded-custom shadow-lg" alt="testimonial author">
                                        <div class="customer-info text-white d-flex align-items-center">
                                            <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                                                class="video-icon popup-youtube text-decoration-none"><i
                                                    class="fas fa-play"></i> <span class="text-white ms-2 small"> Watch
                                                    Video</span></a>
                                        </div>
                                        <div
                                            class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul class="nav nav-pills testimonial-tab-menu mt-60" id="testimonial" role="tablist" data-aos="fade-up"
                    data-aos-delay="100">
                    <li class="nav-item" role="presentation">
                        <div class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link active"
                            data-bs-toggle="pill" data-bs-target="#testimonial-tab-1" role="tab" aria-selected="false">
                            <div class="testimonial-thumb me-3">
                                <img src="assets/img/testimonial/1.jpg" width="50" class="rounded-circle"
                                    alt="testimonial thumb">
                            </div>
                            <div class="author-info">
                                <h6 class="mb-0">Joe Richard</h6>
                                <span>Visual Designer</span>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item" role="presentation">
                        <div class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
                            data-bs-toggle="pill" data-bs-target="#testimonial-tab-2" role="tab" aria-selected="false">
                            <div class="testimonial-thumb me-3">
                                <img src="assets/img/testimonial/2.jpg" width="50" class="rounded-circle"
                                    alt="testimonial thumb">
                            </div>
                            <div class="author-info">
                                <h6 class="mb-0">Rupan Oberoi</h6>
                                <span>Web Designer</span>
                            </div>
                        </div>

                    </li>
                    <li class="nav-item" role="presentation">
                        <div class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
                            data-bs-toggle="pill" data-bs-target="#testimonial-tab-3" role="tab" aria-selected="false">
                            <div class="testimonial-thumb me-3">
                                <img src="assets/img/testimonial/3.jpg" width="50" class="rounded-circle"
                                    alt="testimonial thumb">
                            </div>
                            <div class="author-info">
                                <h6 class="mb-0">Jon Doe</h6>
                                <span>Software Engineer</span>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item" role="presentation">
                        <div class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
                            data-bs-toggle="pill" data-bs-target="#testimonial-tab-4" role="tab" aria-selected="false">
                            <div class="testimonial-thumb me-3">
                                <img src="assets/img/testimonial/4.jpg" width="50" class="rounded-circle"
                                    alt="testimonial thumb">
                            </div>
                            <div class="author-info">
                                <h6 class="mb-0">Hanry Luice</h6>
                                <span>App Developer</span>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item" role="presentation">
                        <div class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
                            data-bs-toggle="pill" data-bs-target="#testimonial-tab-5" role="tab" aria-selected="true">
                            <div class="testimonial-thumb me-3">
                                <img src="assets/img/testimonial/5.jpg" width="50" class="rounded-circle"
                                    alt="testimonial thumb">
                            </div>
                            <div class="author-info">
                                <h6 class="mb-0">Ami Nijai</h6>
                                <span>Customer Support</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!--customer review tab section end-->

<!--integration section start-->
<section class="integration-section ptb-120">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-3">
                <div class="integration-list-wrap">
                    <div class="integration-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Scala">
                        <i class="fa-2x dev devicon-scala-plain colored "></i>
                    </div>
                    <div class="integration-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Akka Toolkit">
                        <img src="assets/img/integations/Akka.svg" alt="integration" class="img-fluid rounded-circle">
                    </div>
                    <div class="integration-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Python">
                        <i class="fa-2x dev devicon-python-plain colored "></i>
                    </div>

                    <div class="integration-4" data-bs-toggle="tooltip" data-bs-placement="top" title="Apache Kafka">
                        <i class="fa-2x dev devicon-apachekafka-original colored "></i>
                    </div>
                    <div class="integration-5" data-bs-toggle="tooltip" data-bs-placement="top" title="Play Framework">
                        <img src="assets/img/integations/play.png" alt="Play Framework" class="img-fluid rounded-circle">
                    </div>
                    <div class="integration-6" data-bs-toggle="tooltip" data-bs-placement="top" title="Zio">
                        <img src="assets/img/integations/zio.png" alt="Zio" class="img-fluid rounded-circle">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="section-heading text-center my-5 my-lg-0 my-xl-0">
                    <h4 class="text-primary h5">Our Top Technologies</h4>
                    <h3>We use the best resources to produce high quality softwares.</h3>
                    <a href="../contact" class="mt-4 btn btn-primary">Send Us a Message</a>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="col-lg-4">
                    <div class="integration-list-wrap">
                        <div class="integration-7" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="React">
                            <i class="fa-2x dev devicon-react-original colored "></i>
                    </div>
                        <div class="integration-8" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Angular">
                            <i class="fa-2x dev devicon-angularjs-plain colored"></i>
                    </div>
                        <div class="integration-9" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Flutter">
                            <i class="fa-2x dev devicon-flutter-plain colored"></i>
                    </div>

                        <div class="integration-10" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Swift">
                            <i class="fa-2x dev devicon-swift-plain colored"></i>
                    </div>
                        <div class="integration-11" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Kotlin">
                            <i class="fa-2x dev devicon-kotlin-plain colored"></i>
                    </div>
                        <div class="integration-12" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Wordpress">
                            <i class="fa-2x dev devicon-wordpress-plain colored"></i>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-100">
            <div class="col-lg-5 col-md-12">
                <a
                    class="mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5">
                    <div class="position-relative connected-app-content">
                        <div class="integration-logo bg-custom-light rounded-circle p-2 d-inline-block">
                            <img src="assets/img/integations/aws.png" width="40" alt="integration" class="img-fluid">
                        </div>
                        <h5>Amazon Web Services</h5>
                        <p class="mb-0 text-body">Competently generate unique e-services and client-based models.
                            Globally engage tactical niche</p>
                    </div>
                    <span
                        class="position-absolute integration-badge badge px-3 py-2 bg-primary-soft text-primary">Ideal Budget</span>
                </a>
            </div>

            <div class="col-lg-5 col-md-12">
                <a
                    class="position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5">
                    <div class="position-relative connected-app-content">
                        <div class="integration-logo bg-custom-light rounded-circle p-2 d-inline-block">
                            <img src="assets/img/integations/firebase.png" width="40" alt="integration" class="img-fluid">
                        </div>
                        <h5>Google Firebase</h5>
                        <p class="mb-0 text-body">Globally engage tactical niche markets rather than client-based
                            competently generate services</p>
                    </div>
                    <span
                        class="position-absolute integration-badge badge px-3 py-2 bg-danger-soft text-danger">Low Budget</span>
                </a>
            </div>
        </div>
    </div>
</section>
<!--integration section end-->

<!--cat subscribe start-->
<section class="cta-subscribe ptb-50">
    <div class="container">
        <div class="bg-dark ptb-120 position-relative overflow-hidden rounded-custom">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-8">
                    <div class="subscribe-info-wrap text-center position-relative z-2">
                        <div class="section-heading">
                            <h4 class="h5 text-pink">Get Free Support</h4>
                            <h2>Let's talk about your project</h2>
                            <p>We can help implement your project for better business revenue.</p>
                        </div>
                        <div class="form-block-banner mw-60 m-auto mt-5">
                            <a href="../contact" class="btn btn-primary">Contact with Us</a>
                        </div>
                        <ul class="nav justify-content-center subscribe-feature-list mt-4">
                            <li class="nav-item">
                                <span><i class="far fa-check-circle text-primary me-2"></i>We respond in less than 24H</span>
                            </li>
                            <li class="nav-item">
                                <span><i class="far fa-check-circle text-primary me-2"></i>Free tech consultation</span>
                            </li>
                            <li class="nav-item">
                                <span><i class="far fa-check-circle text-primary me-2"></i>Provide an estimate in 3 Days</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5"></div>
            <div class="bg-circle rounded-circle circle-shape-1 position-absolute bg-pinkright-5"></div>
        </div>
    </div>
</section>
<!--cat subscribe end-->
