import { Component, OnInit } from '@angular/core';
import { Article, ARTICLES } from 'src/app/models/article';
import { PropertyTag } from 'src/app/models/propertyTag.model';
import { Seo } from 'src/app/models/seo.model';
import { MetaTag } from 'src/app/models/tag.model';
import { SEOService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  articles: Article[]
  propertyTags: PropertyTag[]
  metaTags: MetaTag[]
  seo: Seo
  schema

  constructor(private seoService: SEOService) { 
    this.metaTags = [
      {name: "title", content: "Hiis | Software Company | You share your idea. We get it done."},
      {name: "description", content: "We transform bold business ideas into exceptional digital products. We ideate, design, and develop data-driven digital products made to answer business challenges."},
      {name: "keywords", content: "Full Stack Web Developer, Backend Developer, Senior Scala Developer, Play Framework, AKKA HTTP, AKKA Streams, AKKA Actors, Zio, Cats, Circe, Kafka, Docker, Angular, React"},
      {name: "author", content: "Hiis"},

      //Twitter Tags
      {name: "twitter:card", content: "summary_large_image"},
      {name: "twitter:type", content: "website"},
      {name: "twitter:url", content: "https://www.abanda.me"},
      {name: "twitter:title", content: "Hiis | Software Company | You share your idea. We get it done."},
      {name: "twitter:description", content: "We transform bold business ideas into exceptional digital products. We ideate, design, and develop data-driven digital products made to answer business challenges."},
      {name: "twitter:image", content: "https://www.abanda.me/assets/images/slider/abanda.png"},
      {name: "twitter:creator", content: "@abanda_ludovic"}
    ] 

    this.propertyTags = [
      //Facebook / Open Graph Tags
      {property: "og:type", content: "website"},
      {property: "og:url", content: "https://www.abanda.me"},
      {property: "og:title", content: "Hiis | Software Company | You share your idea. We get it done."},
      {property: "og:description", content: "We transform bold business ideas into exceptional digital products. We ideate, design, and develop data-driven digital products made to answer business challenges."},
      {property: "og:image", content: "https://www.abanda.me/assets/images/slider/abanda.png"},
    ]
    this.articles = new Array()
    let sorted = ARTICLES.sort((a, b) =>  -(b.date.getMilliseconds() - a.date.getMilliseconds()))
    for (let i = 0; i < 3; i++)
      this.articles.push(sorted[i])

  }

  ngOnInit(): void {
    this.seo = new Seo()
    this.seo.title = "Hiis | Software Company | You share your idea. We get it done."
    this.seo.description = "We transform bold business ideas into exceptional digital products. We ideate, design, and develop data-driven digital products made to answer business challenges."
    this.seo.metaTags = this.metaTags
    this.seo.propertyTags = this.propertyTags
    this.schema = {
      "@context": "https://schema.org/",
      "@type": "Person",
      "name": "Hiis",
      "url": "https://www.abanda.me",
      "image": "https://www.abanda.me/assets/images/slider/abanda.png",
      "sameAs": [
        "https://twitter.com/abanda_ludovic/",
        "https://www.linkedin.com/in/ludovic-abanda-8b391a142",
        "https://github.com/icemc",
        "https://www.facebook.com/profile.php?id=100071533531012"
      ],
      "jobTitle": "Software Company | You share your idea. We get it done.",
      "worksFor": {
        "@type": "Organization",
        "name": "Hiis"
      }  
    }
    this.seoService.addTags(this.seo)
  }

}
