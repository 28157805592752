<!--hero section start-->
<section class="hero-section ptb-120 text-white bg-gradient"
    style="background: url('assets/img/hero-dot-bg.png')no-repeat center right">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-10">
                <div class="hero-content-wrap mt-5">
                    <h1 class="fw-bold display-5">Business Intelligence & Data Analysis</h1>
                    <p class="lead">Proactively coordinate quality quality vectors vis-a-vis supply chains. Quickly
                        engage client-centric web services.</p>
                    <div class="action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex">
                        <a href="request-demo.html" class="btn btn-danger me-3">Request For Demo</a>
                        <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                            class="text-decoration-none popup-youtube d-inline-flex align-items-center watch-now-btn mt-3 mt-lg-0 mt-md-0">
                            <i class="fas fa-play"></i> Watch Demo </a>
                    </div>
                    <div class="row justify-content-lg-start mt-60">
                        <h6 class="text-white-70 mb-2">Trusted By 5000+ Worldwide Customers:</h6>
                        <div class="col-4 col-sm-3 my-2 ps-lg-0">
                            <img src="assets/img/clients/client-1.svg" alt="client" class="img-fluid">
                        </div>
                        <div class="col-4 col-sm-3 my-2">
                            <img src="assets/img/clients/client-2.svg" alt="client" class="img-fluid">
                        </div>
                        <div class="col-4 col-sm-3 my-2">
                            <img src="assets/img/clients/client-3.svg" alt="client" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-8 mt-5">
                <div class="hero-img position-relative circle-shape-images">
                    <!--animated shape start-->
                    <ul class="position-absolute animate-element parallax-element circle-shape-list">
                        <li class="layer" data-depth="0.02">
                            <img src="assets/img/shape/circle-1.svg" alt="shape"
                                class="circle-shape-item type-1 hero-1">
                        </li>
                        <li class="layer" data-depth="0.04">
                            <img src="assets/img/shape/circle-1.svg" alt="shape"
                                class="circle-shape-item type-2 hero-1">
                        </li>
                        <li class="layer" data-depth="0.04">
                            <img src="assets/img/shape/circle-1.svg" alt="shape"
                                class="circle-shape-item type-3 hero-1">
                        </li>
                        <li class="layer" data-depth="0.03">
                            <img src="assets/img/shape/circle-1.svg" alt="shape"
                                class="circle-shape-item type-4 hero-1">
                        </li>
                        <li class="layer" data-depth="0.03">
                            <img src="assets/img/shape/circle-1.svg" alt="shape"
                                class="circle-shape-item type-5 hero-1">
                        </li>
                    </ul>
                    <!--animated shape end-->
                    <img src="assets/img/screen/animated-screen-5.svg" alt="hero img"
                        class="img-fluid position-relative z-5">
                </div>
            </div>
        </div>
    </div>
</section>
<!--hero section end-->

<!--feature promo section start-->
<section class="feature-section ptb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12">
                <div class="section-heading text-center">
                    <h4 class="text-primary h5">Build Quality</h4>
                    <h2>Our Special Features</h2>
                    <p>Authoritatively underwhelm excellent methodologies
                        via premium expertise competitive than open-source
                        imperatives disseminate. </p>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <div class="position-relative">
                    <div
                        class="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                        <div class="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                            <i class="fad fa-shield-alt text-primary fa-2x"></i>
                        </div>
                        <h3 class="h5">Quality First</h3>
                        <p class="mb-0">Professionally integrate high standards applications before user friendly
                            leadership skills.</p>
                    </div>
                    <!--pattern start-->
                    <div class="dot-shape-bg position-absolute z--1 left--40 top--40">
                        <img src="assets/img/shape/dot-big-square.svg" alt="shape">
                    </div>
                    <!--pattern end-->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="position-relative">
                    <div class="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0">
                        <div class="feature-icon d-inline-block bg-danger-soft rounded-circle mb-32">
                            <i class="fad fa-comment-alt-smile text-danger fa-2x"></i>
                        </div>
                        <h3 class="h5">Communication</h3>
                        <p class="mb-0">Professionally integrate high standards applications before user friendly
                            leadership skills.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="position-relative">
                    <div class="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0">
                        <div class="feature-icon d-inline-block bg-success-soft rounded-circle mb-32">
                            <i class="fad fa-grin-stars text-success fa-2x"></i>
                        </div>
                        <h3 class="h5">Reliability</h3>
                        <p class="mb-0">Professionally integrate high standards applications before user friendly
                            leadership skills.</p>
                    </div>
                    <!--pattern start-->
                    <div class="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                        <img src="assets/img/shape/dot-big-square.svg" alt="shape">
                    </div>
                    <!--pattern end-->
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature promo section end-->

<!--feature left right content start-->
<section class="feature-section ptb-120">
    <div class="container">
        <div class="row align-content-center justify-content-between">
            <div class="col-lg-5 col-md-12">
                <div class="section-heading mb-5">
                    <div class="icon-box rounded-custom bg-danger shadow-sm d-inline-block">
                        <i class="fal fa-shield-check fa-2x text-white"></i>
                    </div>
                    <h2>Increasing Business Success With Technology</h2>
                    <p>Uniquely benchmark multifunctional catalysts for change via manufactured products brand scalable
                        products. Network orthogonal infomediaries through pandemic initiatives. </p>
                </div>
                <div class="single-feature d-flex mb-4">
                    <span class="fad fa-bezier-curve fa-2x text-primary"></span>
                    <div class="ms-4 mt-2">
                        <h5>Pixel Perfect Design</h5>
                        <p>Credibly syndicate enterprise total linkage whereas cost effective of the art data without
                            multifunctional. </p>
                    </div>
                </div>
                <div class="single-feature d-flex mb-4">
                    <span class="fad fa-code fa-2x text-primary"></span>
                    <div class="ms-4 mt-2">
                        <h5>Development Execution</h5>
                        <p>Synergistically communicate excellent rather than enterprise-wide value quickly architect
                            emerging interfaces.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-8">
                <div class="feature-shape-img position-relative mt-5 mt-lg-0">
                    <div class="img-bg-shape position-absolute">
                        <img src="assets/img/integations/shape.svg" alt="integations">
                    </div>
                    <img src="assets/img/dashkit.png" class="img-fluid skewed-img-right rounded-custom shadow-lg"
                        alt="softtent">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="why-choose-us pt-60 pb-120">
    <div class="container">
        <div class="row justify-content-lg-between align-items-center">
            <div class="col-lg-6 col-12 order-1 order-lg-0">
                <div class="feature-img-holder mt-5 mt-lg-0 mt-xl-0">
                    <img src="assets/img/screen/widget-7.png" class="img-fluid" alt="feature-image">
                </div>
            </div>
            <div class="col-lg-5 col-12">
                <div class="why-choose-content">
                    <div class="icon-box rounded-custom bg-primary shadow-sm d-inline-block">
                        <i class="fal fa-shield-check fa-2x text-white"></i>
                    </div>
                    <h2>Advanced Analytics, Understand Business</h2>
                    <p>Distinctively promote parallel vortals with ubiquitous e-markets. Proactively benchmark turnkey
                        optimize next-generation strategic leadership without resource sucking ideas.</p>
                    <ul class="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                        <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Thought leadership</li>
                        <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Personal branding</li>
                        <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Modernized prospecting
                        </li>
                        <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Better win rates</li>
                        <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Showcasing success</li>
                        <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Sales compliance</li>
                    </ul>
                    <a href="about-us.html" class="read-more-link text-decoration-none">Know More About Us <i
                            class="far fa-arrow-right ms-2"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature left right content end-->

<!--feature section start-->
<section class="feature-section">
    <div class="container-fluid">
        <div class="feature-container mx-lg-5 position-relative bg-gradient ptb-120 rounded-custom">
            <img src="assets/img/feature-bg-1.jpg" alt="feature-bg"
                class="feature-bg-mockup position-absolute w-100 h-100 left-0 right-0 top-0 bottom-0 rounded-custom">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="feature-bg-img-content position-relative z-5">
                            <div class="section-heading">
                                <h2>Build Interactions Visually Website for Your Company</h2>
                                <p>Intrinsicly impact resource sucking initiatives with 24/7 relationships communicate
                                    integrated products via synergistic leadership skills. Conveniently pursue
                                    future-proof web services through empowered bandwidth.</p>
                            </div>

                            <ul class="list-unstyled d-flex flex-wrap list-two-col mt-5 mb-0">
                                <li class="py-3">
                                    <h3 class="feature-number text-primary mb-2">55%</h3>
                                    <p>Compellingly engage corporate scenarios for monetize. </p>
                                </li>
                                <li class="py-3">
                                    <h3 class="feature-number text-primary mb-2">5K+</h3>
                                    <p>Conveniently target enterprise vortals for process-centric. </p>
                                </li>
                                <li class="py-3">
                                    <h3 class="feature-number text-primary mb-2">15M+</h3>
                                    <p>Completely strategize long-term high-impact paradigms. </p>
                                </li>
                                <li class="py-3">
                                    <h3 class="feature-number text-primary mb-2">95%</h3>
                                    <p>Uniquely negotiate compelling platforms via standardized. </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--feature section end-->

<!--integration section start-->
<section class="integration-section pt-60 pb-120">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 col-md-12">
                <div class="section-heading" data-aos="fade-up">
                    <h4 class="h5 text-primary">Technologies</h4>
                    <h2>We use the best modern technologies for your backends</h2>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="text-lg-end mb-5 mb-lg-0" data-aos="fade-up">
                    <a href="/contact" class="btn btn-primary">Let's talk</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="integration-wrapper position-relative w-100">
                    <!--animated shape start-->
                    <ul
                        class="position-absolute animate-element parallax-element shape-service z--1 d-none d-lg-none d-xl-block">

                        <li class="layer" data-depth="0.02">
                            <img src="assets/img/color-shape/feature-2.svg" alt="shape"
                                class="img-fluid position-absolute color-shape-2 z-5">
                        </li>
                        <li class="layer" data-depth="0.03">
                            <img src="assets/img/color-shape/feature-3.svg" alt="shape"
                                class="img-fluid position-absolute color-shape-3">
                        </li>
                    </ul>
                    <!--animated shape end-->
                    <ul class="integration-list list-unstyled mb-0">
                        <li data-aos="fade-up" data-aos-delay="50">
                            <div class="single-integration">
                                <img src="assets/img/integations/airbnb.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="50">
                            <div class="single-integration">
                                <img src="assets/img/integations/figma.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="50">
                            <div class="single-integration">
                                <img src="assets/img/integations/facebook.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="50">
                            <div class="single-integration">
                                <img src="assets/img/integations/sales-force.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="50">
                            <div class="single-integration">
                                <img src="assets/img/integations/atlassian.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="50">
                            <div class="single-integration">
                                <img src="assets/img/integations/dropbox-2.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="single-integration">
                                <img src="assets/img/integations/dynamic-365.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="single-integration">
                                <img src="assets/img/integations/erecruiter.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="single-integration">
                                <img src="assets/img/integations/evernote.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="single-integration">
                                <img src="assets/img/integations/google-icon.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="single-integration">
                                <img src="assets/img/integations/slack.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="single-integration">
                                <img src="assets/img/integations/google-analytics.png" alt="integration"
                                    class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="150">
                            <div class="single-integration">
                                <img src="assets/img/integations/google-drive.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="150">
                            <div class="single-integration">
                                <img src="assets/img/integations/hubspot.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>

                        <li data-aos="fade-up" data-aos-delay="150">
                            <div class="single-integration">
                                <img src="assets/img/integations/instagram.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="150">
                            <div class="single-integration">
                                <img src="assets/img/integations/linkedin.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="150">
                            <div class="single-integration">
                                <img src="assets/img/integations/mailchimp.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="150">
                            <div class="single-integration">
                                <img src="assets/img/integations/marekto.png" alt="integration" class="img-fluid">
                                <h6 class="mb-0 mt-4">Brand Name</h6>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!--integration section end-->

<!--faq section start-->
<section class="faq-section ptb-120 bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-6">
                <div class="section-heading text-center">
                    <h4 class="h5 text-primary">FAQ</h4>
                    <h2>Frequently Asked Questions</h2>
                    <p>Conveniently mesh cooperative services via magnetic outsourcing. Dynamically grow value whereas
                        accurate e-commerce vectors. </p>
                </div>
            </div>
        </div>
        <div class="row align-items-center justify-content-between">
            <div class="col-lg-5 col-12">
                <div class="faq-wrapper">
                    <div class="faq-item mb-5">
                        <h5><span class="h3 text-primary me-2">1.</span> How does back pricing work?</h5>
                        <p>Progressively e-enable collaborative inexpensive supply chains. Efficiently maintain
                            economically methods of empowerment for synergistic sound scenarios.</p>
                    </div>
                    <div class="faq-item mb-5">
                        <h5><span class="h3 text-primary me-2">2.</span> How do I calculate how much price?</h5>
                        <p>Globally benchmark customized mindshare before clicks-and-mortar partnerships. Efficiently
                            maintain economically sound scenarios and whereas client-based progressively. </p>
                    </div>
                    <div class="faq-item">
                        <h5><span class="h3 text-primary me-2">3.</span> Can you show me an example?</h5>
                        <p> Dynamically visualize whereas competitive relationships. Progressively benchmark customized
                            partnerships generate interdependent benefits rather sound scenarios and robust alignments.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="text-center mt-4 mt-lg-0 mt-md-0">
                    <img src="assets/img/faq.svg" alt="faq" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<!--faq section end-->