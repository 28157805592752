<ngx-json-ld [json]="schema"></ngx-json-ld>
<!--hero section start-->
<section class="hero-section ptb-120 text-black bg-gradient"
  style="background: url('assets/img/hero-dot-bg.png') no-repeat center right">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-10 col-xs-12">
        <div class="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
          <h1 class="fw-bold display-5">Welcome to <ngx-typed-writer [strings]="['High-Is, Oops!','HIIS [hai-iz]']"
              [cursorChar]="'_'" [showCursor]="false" [backSpeed]="30" [typeSpeed]="20" [loop]="false">
            </ngx-typed-writer>
          </h1>
          <div class="typewriter-top">
            <p class="lead"><ngx-typed-writer
                [strings]="['HIIS [haɪ-ɪz] pronounced High-Is is a full-service software development company with extensive expereince building large scale applications.', 'We transform bold business ideas into exceptional digital products. Let\'s work together']"
                [cursorChar]="'_'" [showCursor]="true" [backSpeed]="3" [typeSpeed]="20" [loop]="true">
              </ngx-typed-writer>
            </p>
          </div>
          <div class="typewriter-bottom">
            <div class="action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex">
              <a routerLink="." fragment="footer" class="btn btn-primary me-3">Talk to an expert</a>
            </div>
            <div class="row justify-content-lg-start mt-60">
              <h6 class="text-white-70 mb-2">Our Top technologies:</h6>
              <div class="text-start">
                <ul class="list-unstyled list-inline tech-list mb-0">
                  <li class="list-inline-item"><i class="fa-2x devicon-flutter-plain colored"></i></li>
                  <li class="list-inline-item"><i class="fa-2x devicon-react-original colored"></i></li>
                  <li class="list-inline-item"><i class="fa-2x devicon-scala-plain colored"></i></li>
                  <li class="list-inline-item"><i class="fa-2x devicon-android-plain colored"></i></li>
                  <li class="list-inline-item"><i class="fa-2x devicon-apple-original colored"></i></li>
                  <li class="list-inline-item"><i class="fa-2x devicon-mongodb-plain colored"></i></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-8 mt-5 d-none d-sm-block">
        <div class="hero-img position-relative circle-shape-images">
          <!--animated shape start-->
          <ul class="position-absolute animate-element parallax-element circle-shape-list">
            <!-- <li class="layer" data-depth="0.03">
              <img src="assets/img/shape/circle-1.svg" alt="shape" class="circle-shape-item type-0 hero-1">
            </li> -->
            <li class="layer" data-depth="0.02">
              <img src="assets/img/shape/circle-1.svg" alt="shape" class="circle-shape-item type-1 hero-1">
            </li>
            <li class="layer" data-depth="0.04">
              <img src="assets/img/shape/circle-1.svg" alt="shape" class="circle-shape-item type-2 hero-1">
            </li>
            <li class="layer" data-depth="0.04">
              <img src="assets/img/shape/circle-1.svg" alt="shape" class="circle-shape-item type-3 hero-1">
            </li>
            <li class="layer" data-depth="0.03">
              <img src="assets/img/shape/circle-1.svg" alt="shape" class="circle-shape-item type-4 hero-1">
            </li>
            <li class="layer" data-depth="0.03">
              <img src="assets/img/shape/circle-1.svg" alt="shape" class="circle-shape-item type-5 hero-1">
            </li>
          </ul>
          <!--animated shape end-->
          <img src="assets/img/lion-square.png" alt="hero img" class="img-fluid position-relative z-5">
        </div>
      </div>
    </div>
  </div>
</section>
<!--hero section end-->

<!--feature promo section start-->
<section class="feature-promo ptb-120 bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-10">
        <div class="section-heading text-center">
          <h2>You share your idea. We get it done</h2>
          <p>Build world-class digital products with a team of design, development and strategy experts. All
            in one place.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6">
        <div class="single-feature-promo p-lg-5 p-4 text-center mt-3">
          <div class="feature-icon icon-center pb-5 rounded-custom bg-primary">
            <i class="fal fa-layer-group fa-2x text-white"></i>
          </div>
          <div class="feature-info-wrap">
            <h3 class="h5">Good Performance</h3>
            <p>The technologies we use for building our projects are carefully picked to create highly
              performant, scalable and concurrent applications.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-feature-promo p-lg-5 p-4 text-center mt-3">
          <div class="feature-icon icon-center pb-5 rounded-custom bg-pink">
            <i class="fal fa-shield-check fa-2x text-white"></i>
          </div>
          <div class="feature-info-wrap">
            <h3 class="h5">Highly Secure</h3>
            <p>Secuity is at the core of every successful software project. The system we design are highly
              secure and based on industry standards. </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="single-feature-promo p-lg-5 p-4 text-center mt-3">
          <div class="feature-icon icon-center pb-5 rounded-custom bg-dark">
            <i class="fal fa-code fa-2x text-white"></i>
          </div>
          <div class="feature-info-wrap">
            <h3 class="h5">Fast Development</h3>
            <p>Cut off development time to nearly 50% with our team of highly trained software developers.
              Get your idea to the market within some few months.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-lg-5 pt-3">
      <div class="col-lg-6 mt-4">
        <div class="position-relative d-flex flex-column h-100 flex-wrap bg-danger-soft p-5 rounded-custom">
          <div class="cta-left-info mb-2">
            <h5>Amazing technologies</h5>
            <p>We build amazing softwares for our clients. You will love working with Hiis. </p>
          </div>
          <div class="mt-auto">
            <a href="../contact" class="btn btn-outline-primary btn-sm">Start For Free</a>
          </div>
          <div class="cta-img position-absolute right-0 bottom-0">
            <img src="assets/img/cta-img-1.png" alt="cta img" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="col-lg-6 mt-4">
        <div class="position-relative d-flex flex-column h-100 flex-wrap bg-primary-soft p-5 rounded-custom">
          <div class="cta-left-info mb-2">
            <h5>Excellent user experience</h5>
            <p>Love it when the UI is beautiful and effective. So do we! Talk to us today.</p>
          </div>
          <div class="mt-auto">
            <a href="../contact" class="btn btn-outline-primary btn-sm">Start For Free</a>
          </div>
          <div class="cta-img position-absolute right-0 bottom-0">
            <img src="assets/img/cta-img-2.png" alt="cta img" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--features grid section start-->
<section class="feature-section bg-light" id="services">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-10">
        <div class="section-heading text-center">
          <h2>Our Services</h2>
          <p>Build world-class digital products with a team of design, development and strategy experts. All
            in one place.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="feature-grid">
          <div class="feature-card bg-white shadow-sm highlight-card rounded-custom p-5">
            <div class="icon-box d-inline-block rounded-circle bg-primary-soft mb-32">
              <i class="fal fa-mobile-button icon-md text-primary"></i>
            </div>
            <div class="feature-content">
              <h3 class="h5">Mobile Development</h3>
              <p>It takes lots of effort, planning and research to create an app that succeeds. We want to
                take the work off your shoulders. Our UIs are beautiful and intuitive, your users will
                love them.</p>
              <h6 class="mt-4">Included with...</h6>
              <ul class="list-unstyled mb-0">
                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Cross Platform
                </li>
                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Android
                </li>
                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>IOS
                </li>
                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Beautiful UI
                </li>
                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Great UX
                </li>
                <li class="py-1"><i class="fad fa-check-circle me-2 text-primary"></i>Clean and
                  modern
                </li>
              </ul>
            </div>
            <a href="../services/mobile-development" class="link-with-icon text-decoration-none mt-3">View
              Details <i class="far fa-arrow-right"></i></a>
          </div>
          <div class="feature-card bg-white shadow-sm rounded-custom p-5">
            <div class="icon-box d-inline-block rounded-circle bg-primary mb-32">
              <i class="fas devicon-graphql-plain icon-md text-white"></i>
            </div>
            <div class="feature-content">
              <h3 class="h5">Backend Development</h3>
              <p class="mb-0">Today it's customary to design your tech infrastructures using a micro
                service architecture. We are here to help you extends your APIs as you wish. Our team is
                well versed with several backend languages and frameworks.</p>
            </div>
            <a href="../services/backend-development" class="link-with-icon text-decoration-none mt-3">View
              Details <i class="far fa-arrow-right"></i></a>
          </div>
          <div class="feature-card bg-white shadow-sm rounded-custom p-5">
            <div class="icon-box d-inline-block rounded-circle bg-pink mb-32">
              <i class="fab fa-html5 icon-md text-white"></i>
            </div>
            <div class="feature-content">
              <h3 class="h5">Frontend Development</h3>
              <p class="mb-0">Building professional grade Web Apps requires a lot of efforts and steps to
                get it right, reason why we are here to offer our expertise in building highly scalable
                Web apps be it Web 2 or Web 3 (Smart Contracts)</p>
            </div>
            <a href="../services/frontend-development" class="link-with-icon text-decoration-none mt-3">View
              Details <i class="far fa-arrow-right"></i></a>
          </div>
          <div class="feature-card bg-white shadow-sm rounded-custom p-5">
            <div class="icon-box d-inline-block rounded-circle bg-pink mb-32">
              <i class="fa fa-laptop-code icon-sm text-white"></i>
            </div>
            <div class="feature-content">
              <h3 class="h5">Custom Software Development</h3>
              <p class="mb-0">In need of a custom software for your organization? Our team of senior
                software engineers can make that possible. We create applications that astound users and
                bring measurable business results.</p>
            </div>
            <a href="../services/custom-software" class="link-with-icon text-decoration-none mt-3">View
              Details <i class="far fa-arrow-right"></i></a>
          </div>
          <div class="feature-card bg-white shadow-sm rounded-custom p-5">
            <div class="icon-box d-inline-block rounded-circle bg-primary mb-32">
              <i class="fa fa-graduation-cap icon-sm text-white"></i>
            </div>
            <div class="feature-content">
              <h3 class="h5">Training & Mentoring</h3>
              <p class="mb-0">With the growing need of developers around the world, we train & mentor
                young developers in the languages and technologies we master in order to make them ready
                for the international job market.</p>
            </div>
            <a href="../services/training" class="link-with-icon text-decoration-none mt-3">View Details <i
                class="far fa-arrow-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--features grid section end-->

<!--why choose us section start-->
<section class="why-choose-us ptb-120  bg-light">
  <div class="container">
    <div class="row justify-content-lg-between align-items-center">
      <div class="col-lg-5 col-12">
        <div class="feature-img-holder mt-4 mt-lg-0 mt-xl-0">
          <img src="assets/img/lion-square.png" class="img-fluid" alt="feature-image">
        </div>
      </div>
      <div class="col-lg-7 col-12">
        <div class="why-choose-content">
          <div class="icon-box rounded-custom bg-primary shadow-sm d-inline-block">
            <i class="fal fa-shield-check fa-2x text-white"></i>
          </div>
          <h2>Why choose us</h2>
          <p>Searching for a partner that will take the process of software development off your hands? You’ve
            come to the right place. We ideate, design, and develop data-driven digital products made to
            answer business challenges. We offer 360° services to smoothly guide you on your way to creating
            a seamless digital masterpiece.</p>
          <ul class="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
            <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Honesty</li>
            <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Motivated people</li>
            <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Financial integrity
            </li>
            <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Better win rates</li>
            <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Honoring our word</li>
            <li class="py-1"><i class="fas fa-check-circle me-2 text-primary"></i>Sales compliance</li>
          </ul>
          <a href="../about" class="read-more-link text-decoration-none">Know More About Us <i
              class="far fa-arrow-right ms-2"></i></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!--why choose us section end-->

<!--customer review tab section start-->
<section class="customer-review-tab ptb-100 bg-gradient text-white  position-relative z-2">
  <div class="container">
    <div class="row justify-content-center align-content-center">
      <div class="col-md-10 col-lg-6">
        <div class="section-heading text-center">
          <h4 class="h5 text-pink text-primary">Testimonial</h4>
          <h2>What They Say About Us</h2>
          <p>Uniquely promote adaptive quality vectors rather than stand-alone e-markets. pontificate
            alternative architectures whereas iterate.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="tab-content" id="testimonial-tabContent">
          <div class="tab-pane fade active show" id="testimonial-tab-1" role="tabpanel">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 col-md-6">
                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                  <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                    class="img-fluid mb-32">
                  <div class="blockquote-title-review mb-4">
                    <h3 class="mb-0 h4 fw-semi-bold">The Best Template You Got to Have it!</h3>
                    <ul class="review-rate mb-0 list-unstyled list-inline">
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                    </ul>
                  </div>

                  <blockquote class="blockquote">
                    <p>Globally network long-term high-impact schemas vis-a-vis distinctive
                      e-commerce
                      cross-media infrastructures rather than ethical sticky alignments rather
                      than global. Plagiarize technically sound total linkage for leveraged value
                      media web-readiness and premium processes.</p>
                  </blockquote>
                  <div class="author-info mt-4">
                    <h6 class="mb-0">Joe Richard</h6>
                    <span>Visual Designer</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <div class="author-img-wrap pt-5 ps-5">
                  <div class="testimonial-video-wrapper position-relative">
                    <img src="assets/img/testimonial/t-1.jpg" class="img-fluid rounded-custom shadow-lg"
                      alt="testimonial author">
                    <div class="customer-info text-white d-flex align-items-center">
                      <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                        class="video-icon popup-youtube text-decoration-none"><i class="fas fa-play"></i> <span
                          class="text-white ms-2 small"> Watch
                          Video</span></a>
                    </div>
                    <div
                      class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="testimonial-tab-2" role="tabpanel">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 col-md-6">
                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                  <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                    class="img-fluid mb-32">
                  <div class="blockquote-title-review mb-4">
                    <h3 class="mb-0 h4 fw-semi-bold">Embarrassed by the First Version.</h3>
                    <ul class="review-rate mb-0 list-unstyled list-inline">
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                    </ul>
                  </div>
                  <blockquote class="blockquote">
                    <p>Energistically streamline robust architectures whereas distributed
                      mindshare. Intrinsicly leverage other's backend growth strategies
                      through 24/365 products. Conveniently pursue revolutionary communities for
                      compelling process improvements. </p>
                  </blockquote>
                  <div class="author-info mt-4">
                    <h6 class="mb-0">Rupan Oberoi</h6>
                    <span>Web Designer</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <div class="author-img-wrap pt-5 ps-5">
                  <div class="testimonial-video-wrapper position-relative">
                    <img src="assets/img/testimonial/t-2.jpg" class="img-fluid rounded-custom shadow-lg"
                      alt="testimonial author">
                    <div class="customer-info text-white d-flex align-items-center">
                      <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                        class="video-icon popup-youtube text-decoration-none"><i class="fas fa-play"></i> <span
                          class="text-white ms-2 small"> Watch
                          Video</span></a>
                    </div>
                    <div
                      class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="testimonial-tab-3" role="tabpanel">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 col-md-6">
                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                  <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                    class="img-fluid mb-32">
                  <div class="blockquote-title-review mb-4">
                    <h3 class="mb-0 h4 fw-semi-bold">Amazing Quiety template!</h3>
                    <ul class="review-rate mb-0 list-unstyled list-inline">
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                    </ul>
                  </div>
                  <blockquote class="blockquote">
                    <p>
                      Appropriately negotiate interactive niches rather orchestrate scalable
                      benefits whereas flexible systems. Objectively grow quality outsourcing
                      without vertical methods of empowerment. Assertively negotiate just in time
                      innovation after client-centered thinking.
                    </p>
                  </blockquote>
                  <div class="author-info mt-4">
                    <h6 class="mb-0">Jon Doe</h6>
                    <span>Software Engineer</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <div class="author-img-wrap pt-5 ps-5">
                  <div class="testimonial-video-wrapper position-relative">
                    <img src="assets/img/testimonial/t-3.jpg" class="img-fluid rounded-custom shadow-lg"
                      alt="testimonial author">
                    <div class="customer-info text-white d-flex align-items-center">
                      <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                        class="video-icon popup-youtube text-decoration-none"><i class="fas fa-play"></i> <span
                          class="text-white ms-2 small"> Watch
                          Video</span></a>
                    </div>
                    <div
                      class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="testimonial-tab-4" role="tabpanel">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 col-md-6">
                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                  <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                    class="img-fluid mb-32">
                  <div class="blockquote-title-review mb-4">
                    <h3 class="mb-0 h4 fw-semi-bold">Best Template for SAAS Company!</h3>
                    <ul class="review-rate mb-0 list-unstyled list-inline">
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                    </ul>
                  </div>
                  <blockquote class="blockquote">
                    <p>Competently repurpose cost effective strategic theme areas and customer
                      directed meta-services. Objectively orchestrate orthogonal initiatives
                      after enterprise-wide bandwidth. Dynamically generate extensive through
                      cooperative channels time partnerships. </p>
                  </blockquote>
                  <div class="author-info mt-4">
                    <h6 class="mb-0">Hanry Luice</h6>
                    <span>App Developer</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <div class="author-img-wrap pt-5 ps-5">
                  <div class="testimonial-video-wrapper position-relative">
                    <img src="assets/img/testimonial/t-4.jpg" class="img-fluid rounded-custom shadow-lg"
                      alt="testimonial author">
                    <div class="customer-info text-white d-flex align-items-center">
                      <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                        class="video-icon popup-youtube text-decoration-none"><i class="fas fa-play"></i> <span
                          class="text-white ms-2 small"> Watch
                          Video</span></a>
                    </div>
                    <div
                      class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="testimonial-tab-5" role="tabpanel">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 col-md-6">
                <div class="testimonial-tab-content mb-5 mb-lg-0 mb-md-0">
                  <img src="assets/img/testimonial/quotes-left.svg" alt="testimonial quote" width="65"
                    class="img-fluid mb-32">
                  <div class="blockquote-title-review mb-4">
                    <h3 class="mb-0 h4 fw-semi-bold">It is Undeniably Good!</h3>
                    <ul class="review-rate mb-0 list-unstyled list-inline">
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                      <li class="list-inline-item"><i class="fas fa-star text-pink"></i>
                      </li>
                    </ul>
                  </div>
                  <blockquote class="blockquote">
                    <p>Appropriately disintermediate one-to-one vortals through cross functional
                      infomediaries. Collaboratively pursue multidisciplinary systems through
                      stand-alone architectures. Progressively transition covalent architectures
                      whereas vertical applications procrastinate professional.</p>
                  </blockquote>
                  <div class="author-info mt-4">
                    <h6 class="mb-0">Ami Nijai</h6>
                    <span>Customer Support</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <div class="author-img-wrap pt-5 ps-5">
                  <div class="testimonial-video-wrapper position-relative">
                    <img src="assets/img/testimonial/t-5.jpg" class="img-fluid rounded-custom shadow-lg"
                      alt="testimonial author">
                    <div class="customer-info text-white d-flex align-items-center">
                      <a href="http://www.youtube.com/watch?v=hAP2QF--2Dg"
                        class="video-icon popup-youtube text-decoration-none"><i class="fas fa-play"></i> <span
                          class="text-white ms-2 small"> Watch
                          Video</span></a>
                    </div>
                    <div
                      class="position-absolute bg-primary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-pills testimonial-tab-menu mt-60" id="testimonial" role="tablist">
          <li class="nav-item" role="presentation">
            <div
              class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link active"
              data-bs-toggle="pill" data-bs-target="#testimonial-tab-1" role="tab" aria-selected="false">
              <div class="testimonial-thumb me-3">
                <img src="assets/img/testimonial/1.jpg" width="50" class="rounded-circle" alt="testimonial thumb">
              </div>
              <div class="author-info">
                <h6 class="mb-0">Joe Richard</h6>
                <span>Visual Designer</span>
              </div>
            </div>
          </li>
          <li class="nav-item" role="presentation">
            <div
              class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
              data-bs-toggle="pill" data-bs-target="#testimonial-tab-2" role="tab" aria-selected="false">
              <div class="testimonial-thumb me-3">
                <img src="assets/img/testimonial/2.jpg" width="50" class="rounded-circle" alt="testimonial thumb">
              </div>
              <div class="author-info">
                <h6 class="mb-0">Rupan Oberoi</h6>
                <span>Web Designer</span>
              </div>
            </div>

          </li>
          <li class="nav-item" role="presentation">
            <div
              class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
              data-bs-toggle="pill" data-bs-target="#testimonial-tab-3" role="tab" aria-selected="false">
              <div class="testimonial-thumb me-3">
                <img src="assets/img/testimonial/3.jpg" width="50" class="rounded-circle" alt="testimonial thumb">
              </div>
              <div class="author-info">
                <h6 class="mb-0">Jon Doe</h6>
                <span>Software Engineer</span>
              </div>
            </div>
          </li>
          <li class="nav-item" role="presentation">
            <div
              class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
              data-bs-toggle="pill" data-bs-target="#testimonial-tab-4" role="tab" aria-selected="false">
              <div class="testimonial-thumb me-3">
                <img src="assets/img/testimonial/4.jpg" width="50" class="rounded-circle" alt="testimonial thumb">
              </div>
              <div class="author-info">
                <h6 class="mb-0">Hanry Luice</h6>
                <span>App Developer</span>
              </div>
            </div>
          </li>
          <li class="nav-item" role="presentation">
            <div
              class="nav-link d-flex align-items-center rounded-custom border border-light border-2 testimonial-tab-link"
              data-bs-toggle="pill" data-bs-target="#testimonial-tab-5" role="tab" aria-selected="true">
              <div class="testimonial-thumb me-3">
                <img src="assets/img/testimonial/5.jpg" width="50" class="rounded-circle" alt="testimonial thumb">
              </div>
              <div class="author-info">
                <h6 class="mb-0">Ami Nijai</h6>
                <span>Customer Support</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--customer review tab section end-->

<!--our work process start-->
<section class="work-process ptb-100 bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-10">
        <div class="section-heading text-center">
          <h4 class="h5 text-primary">Process</h4>
          <h2>How we work at HIIS</h2>
        </div>
      </div>
    </div>
    <div class="row align-items-center justify-content-between">
      <div class="col-lg-5 col-md-12 order-1 order-lg-0">
        <div class="img-wrap color-gray">
          <img src="assets/img/hiis-long.png" alt="work process" class="img-fluid rounded-custom">
        </div>
      </div>
      <div class="col-lg-6 col-md-12 order-0 order-lg-1">
        <ul class="work-process-list list-unstyled">
          <li class="d-flex align-items-start mb-4">
            <div class="process-icon-2 border border-2 rounded-circle bg-primary me-4 mt-2">
              <i class="far fa-envelope fa-2x text-white"></i>
            </div>
            <div class="icon-content">
              <span class="text-primary h6">Step 1</span>
              <h3 class="h5 mb-2">Send us a message</h3>
              <p>You send us a message through our website with details about your project. We will
                respond to you as quickly as possible, usually less than 12H.
              </p>
            </div>
          </li>
          <li class="d-flex align-items-start mb-4">
            <div class="process-icon-2 border border-2 rounded-circle bg-primary text-white me-4 mt-2">
              <i class="far fa-brain fa-2x text-white"></i>
            </div>
            <div class="icon-content">
              <span class="text-primary h6">Step 2</span>
              <h3 class="h5 mb-2">Our team accesses the opportunity</h3>
              <p>Our team of senior engineers will access the opportunities. This process usually takes up
                to 1 working day.
              </p>
            </div>
          </li>
          <li class="d-flex align-items-start mb-4">
            <div class="process-icon-2 border border-2 rounded-circle bg-primary me-4 mt-2">
              <i class="far fa-mobile-iphone fa-2x text-white"></i>
            </div>
            <div class="icon-content">
              <span class="text-primary h6">Step 3</span>
              <h3 class="h5 mb-2">We jump on a ~1h Discovery Call with you</h3>
              <p>Interactively whiteboard transparent testing procedures before
                bricks-and-clicks initiatives administrate competencies.
              </p>
            </div>
          </li>
          <li class="d-flex align-items-start mb-4 mb-lg-0">
            <div class="process-icon-2 border border-2 rounded-circle bg-primary me-4 mt-2">
              <i class="far fa-calendar-clock fa-2x text-white"></i>
            </div>
            <div class="icon-content">
              <span class="text-primary h6">Step 4</span>
              <h3 class="h5 mb-2">We prepare an offer with a project roadmap</h3>
              <p>We prepare an offer with a roadmap and project intake plan, based on the best effort to
                budget ratio.
              </p>
            </div>
          </li>
          <li class="d-flex align-items-start mb-4 mb-lg-0">
            <div class="process-icon-2 border border-2 rounded-circle bg-primary me-4 mt-2">
              <i class="fad fa-handshake fa-2x text-white"></i>
            </div>
            <div class="icon-content">
              <span class="text-primary h6">Step 5</span>
              <h3 class="h5 mb-2">We negotiate agreement</h3>
              <p>Takes up to 6 working days - the time may be shorter depending on your legal team's
                responsiveness.
              </p>
            </div>
          </li>
          <li class="d-flex align-items-start mb-4 mb-lg-0">
            <div class="process-icon-2 border border-2 rounded-circle bg-primary me-4 mt-2">
              <i class="fad fa-check-double fa-2x text-white"></i>
            </div>
            <div class="icon-content">
              <span class="text-primary h6">Step 6</span>
              <h3 class="h5 mb-2">Hire/Project Development</h3>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!--our work process end-->

<!--integration section start-->
<section class="integration-section ptb-120">
  <div class="container">
    <div class="row align-items-center justify-content-between">
      <div class="col-lg-3">
        <div class="integration-list-wrap">
          <div class="integration-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Scala">
            <i class="fa-2x dev devicon-scala-plain colored "></i>
          </div>
          <div class="integration-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Akka Toolkit">
            <img src="assets/img/integations/Akka.svg" alt="integration" class="img-fluid rounded-circle">
          </div>
          <div class="integration-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Python">
            <i class="fa-2x dev devicon-python-plain colored "></i>
          </div>

          <div class="integration-4" data-bs-toggle="tooltip" data-bs-placement="top" title="Apache Kafka">
            <i class="fa-2x dev devicon-apachekafka-original colored "></i>
          </div>
          <div class="integration-5" data-bs-toggle="tooltip" data-bs-placement="top" title="Play Framework">
            <img src="assets/img/integations/play.png" alt="Play Framework" class="img-fluid rounded-circle">
          </div>
          <div class="integration-6" data-bs-toggle="tooltip" data-bs-placement="top" title="Zio">
            <img src="assets/img/integations/zio.png" alt="Zio" class="img-fluid rounded-circle">
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="section-heading text-center my-5 my-lg-0 my-xl-0">
          <h4 class="text-primary h5">Our Top Technologies</h4>
          <h3>We use the best resources to produce high quality softwares.</h3>
          <a href="../contact" class="mt-4 btn btn-primary">Send Us a Message</a>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="col-lg-4">
          <div class="integration-list-wrap">
            <div class="integration-7" data-bs-toggle="tooltip" data-bs-placement="top" title="React">
              <i class="fa-2x dev devicon-react-original colored "></i>
            </div>
            <div class="integration-8" data-bs-toggle="tooltip" data-bs-placement="top" title="Angular">
              <i class="fa-2x dev devicon-angularjs-plain colored"></i>
            </div>
            <div class="integration-9" data-bs-toggle="tooltip" data-bs-placement="top" title="Flutter">
              <i class="fa-2x dev devicon-flutter-plain colored"></i>
            </div>

            <div class="integration-10" data-bs-toggle="tooltip" data-bs-placement="top" title="Swift">
              <i class="fa-2x dev devicon-swift-plain colored"></i>
            </div>
            <div class="integration-11" data-bs-toggle="tooltip" data-bs-placement="top" title="Kotlin">
              <i class="fa-2x dev devicon-kotlin-plain colored"></i>
            </div>
            <div class="integration-12" data-bs-toggle="tooltip" data-bs-placement="top" title="Wordpress">
              <i class="fa-2x dev devicon-wordpress-plain colored"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-100">
      <div class="col-lg-5 col-md-12">
        <a
          class="mb-4 mb-lg-0 mb-xl-0 position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5">
          <div class="position-relative connected-app-content">
            <div class="integration-logo bg-custom-light rounded-circle p-2 d-inline-block">
              <img src="assets/img/integations/aws.png" width="40" alt="integration" class="img-fluid">
            </div>
            <h5>Amazon Web Services</h5>
            <p class="mb-0 text-body">Competently generate unique e-services and client-based models.
              Globally engage tactical niche</p>
          </div>
          <span class="position-absolute integration-badge badge px-3 py-2 bg-primary-soft text-primary">Ideal
            Budget</span>
        </a>
      </div>

      <div class="col-lg-5 col-md-12">
        <a
          class="position-relative text-decoration-none connected-app-single border border-light border-2 rounded-custom d-block overflow-hidden p-5">
          <div class="position-relative connected-app-content">
            <div class="integration-logo bg-custom-light rounded-circle p-2 d-inline-block">
              <img src="assets/img/integations/firebase.png" width="40" alt="integration" class="img-fluid">
            </div>
            <h5>Google Firebase</h5>
            <p class="mb-0 text-body">Globally engage tactical niche markets rather than client-based
              competently generate services</p>
          </div>
          <span class="position-absolute integration-badge badge px-3 py-2 bg-danger-soft text-danger">Low Budget</span>
        </a>
      </div>
    </div>
  </div>
</section>
<!--integration section end-->

<!--cat subscribe start-->
<section class="cta-subscribe ptb-50">
  <div class="container">
    <div class="bg-dark ptb-120 position-relative overflow-hidden rounded-custom">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-8">
          <div class="subscribe-info-wrap text-center position-relative z-2">
            <div class="section-heading">
              <h4 class="h5 text-pink">Get Free Support</h4>
              <h2>Let's talk about your project</h2>
              <p>We can help implement your project for better business revenue.</p>
            </div>
            <div class="form-block-banner mw-60 m-auto mt-5">
              <a href="../contact" class="btn btn-pink">Contact with Us</a>
            </div>
            <ul class="nav justify-content-center subscribe-feature-list mt-4">
              <li class="nav-item">
                <span><i class="far fa-check-circle text-primary me-2"></i>We respond in less than 24H</span>
              </li>
              <li class="nav-item">
                <span><i class="far fa-check-circle text-primary me-2"></i>Free tech consultation</span>
              </li>
              <li class="nav-item">
                <span><i class="far fa-check-circle text-primary me-2"></i>Provide an estimate in 3 Days</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5"></div>
      <div class="bg-circle rounded-circle circle-shape-1 position-absolute bg-pinkright-5"></div>
    </div>
  </div>
</section>
<!--cat subscribe end-->