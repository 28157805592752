import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-react-native',
  templateUrl: './react-native.component.html',
  styleUrls: ['./react-native.component.scss']
})
export class ReactNativeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
