import { Component, OnInit, Input, HostListener } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  dark: boolean = true;
  sticky: boolean = false;

  whiteHeadersPages = [
    "/services/frontend-development"
  ]

  constructor(private router: Router, private route: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
        this.dark = !this.whiteHeadersPages.includes(router.url)
        // console.log(this.dark)
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    })
  }

  @HostListener('window:scroll', ['$event']) onscroll() {
      if (window.scrollY > 80) {
        this.sticky = true;
      } else {
        this.sticky = false;
      }
    }

    ngOnInit(): void {}

  }
