<!--hero section start-->
<section class="hero-section ptb-120 bg-gradient text-white"
    style="background: url('assets/img/shape/dot-dot-wave-shape.svg')no-repeat bottom left">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-xl-5 col-lg-5">
                <div class="hero-content-wrap text-center text-xl-start text-lg-start mt-5 mt-lg-0 mt-xl-0"
                    data-aos="fade-up">
                    <h1 class="fw-bold display-5">Advanced Solutions for Business</h1>
                    <p class="lead">Proactively coordinate quality quality vectors vis-a-vis supply chains quickly
                        engage client.</p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 mt-lg-5 mt-4">
                <div class="hero-img-wrap position-relative" data-aos="fade-up">
                    <div class="hero-screen-wrap">
                        <div class="phone-screen">
                            <img src="assets/img/screen/phone-screen.png" alt="hero image"
                                class="position-relative img-fluid">
                        </div>
                        <div class="mac-screen">
                            <img src="assets/img/screen/mac-screen.png" alt="hero image"
                                class="position-relative img-fluid rounded-custom">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--hero section end-->

<!--highlight promo start-->
<section class="promo-section ptb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-6">
                <div class="section-heading text-center" data-aos="fade-up">
                    <h2>Everything We Do it With Love</h2>
                    <p>Progressively deploy market positioning catalysts for change and technically sound
                        authoritatively e-enable resource-leveling infrastructures. </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <div class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-4"
                    data-aos="fade-up" data-aos-delay="50">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4">150+</h3>
                        <h2 class="h5">Completed Projects</h2>
                        <p class="mb-0">Rapidiously embrace distinctive best practices after B2B syndicate. </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-4"
                    data-aos="fade-up" data-aos-delay="100">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4">1.5k</h3>
                        <h2 class="h5">Happy Client</h2>
                        <p class="mb-0">Energistically impact synergistic convergence for leadership..</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-0"
                    data-aos="fade-up" data-aos-delay="150">
                    <div class="promo-card-info">
                        <h3 class="display-5 fw-medium mb-4">90%</h3>
                        <h2 class="h5">24/7 Support</h2>
                        <p class="mb-0">Synergistically revolutionize leadership whereas platform. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--highlight promo end-->

<!--faq section start-->
<section class="faq-section ptb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7 col-12">
                <div class="section-heading text-center">
                    <h5 class="h6 text-primary">FAQ</h5>
                    <h2>Frequently Asked Questions</h2>
                    <p>Completely whiteboard top-line channels and fully tested value. Competently generate
                        testing procedures before visionary maintainable growth strategies for maintainable.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-between">
            <div class="col-lg-3 col-md-4 d-none d-md-block d-lg-block">
                <div class="support-article-sidebar sticky-sidebar bg-light rounded-custom p-4 mt-5">
                    <ul class="nav flex-column nav-pills support-article-tab accordion-list w-100">
                        <li class="nav-link"><a href="#faq-accordion-1" class="text-decoration-none">Retails and
                                Customer</a></li>
                        <li class="nav-link"><a href="#faq-accordion-2" class="text-decoration-none">Payments
                                Query</a></li>
                        <li class="nav-link"><a href="#faq-accordion-3" class="text-decoration-none">Setup or
                                Installment</a></li>
                        <li class="nav-link"><a href="#faq-accordion-4" class="text-decoration-none">Technical
                                Support</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-8 col-md-8">
                <div class="accordion-faq-content-wrap">
                    <div id="faq-accordion-1" class="py-4">
                        <h4>Retails and Customers</h4>
                        <div class="accordion faq-accordion my-5" id="accordionExample-2">
                            <div class="accordion-item border border-2 active">
                                <h5 class="accordion-header" id="faq-2-1">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-2-1" aria-expanded="true">
                                        How does back pricing work?
                                    </button>
                                </h5>
                                <div id="collapse-2-1" class="accordion-collapse collapse show"
                                    aria-labelledby="faq-2-1" data-bs-parent="#accordionExample-2">
                                    <div class="accordion-body">
                                        Dramatically formulate cross-unit products with web-enabled action
                                        items. Quickly maximize extensible methods of empowerment without
                                        out-of-the-box initiatives. Proactively myocardinate functional total
                                        linkage rather than seamless information. Holisticly fabricate timely
                                        initiatives vis-a-vis high-quality imperatives. Continually deploy
                                        open-source content through professional customer service.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-2-2">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-2-2" aria-expanded="false">
                                        Can you show me an example?
                                    </button>
                                </h5>
                                <div id="collapse-2-2" class="accordion-collapse collapse" aria-labelledby="faq-2-2"
                                    data-bs-parent="#accordionExample-2">
                                    <div class="accordion-body">
                                        Continually innovate technically sound internal or "organic" sources
                                        with market positioning content. Completely drive optimal intellectual
                                        capital vis-a-vis global human capital. Intrinsicly administrate robust
                                        materials and bleeding-edge resources. Dynamically envisioneer
                                        next-generation markets vis-a-vis market-driven applications
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-2-3">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-2-3" aria-expanded="false">
                                        How do I processing I need?
                                    </button>
                                </h5>
                                <div id="collapse-2-3" class="accordion-collapse collapse" aria-labelledby="faq-2-3"
                                    data-bs-parent="#accordionExample-2">
                                    <div class="accordion-body">
                                        Phosfluorescently deliver cooperative testing procedures after
                                        integrated communities. Dramatically simplify resource-leveling models
                                        with unique outsourcing. Professionally simplify covalent partnerships
                                        whereas market positioning best practices. Collaboratively utilize
                                        magnetic technology for robust technology.
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-2-4">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-2-4" aria-expanded="false">
                                        What happens if I go over my limit?
                                    </button>
                                </h5>
                                <div id="collapse-2-4" class="accordion-collapse collapse" aria-labelledby="faq-2-4"
                                    data-bs-parent="#accordionExample-2">
                                    <div class="accordion-body">
                                        Credibly facilitate leveraged process improvements for equity invested
                                        infrastructures. Continually mesh top-line human capital with
                                        backward-compatible outsourcing. Rapidiously coordinate intuitive
                                        deliverables rather than parallel metrics. Interactively monetize
                                        customer directed convergence and parallel sources. Enthusiastically
                                        architect client-centric e-services whereas granular e-commerce.
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-2-5">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-2-5" aria-expanded="false">
                                        How do I calculate how much processing I need?
                                    </button>
                                </h5>
                                <div id="collapse-2-5" class="accordion-collapse collapse" aria-labelledby="faq-2-5"
                                    data-bs-parent="#accordionExample-2">
                                    <div class="accordion-body">
                                        Distinctively enable premier potentialities through market positioning
                                        models. Distinctively extend unique infomediaries without
                                        enterprise-wide ideas. Objectively deploy multifunctional catalysts for
                                        change for installed base content. Seamlessly create go forward
                                        convergence through quality schemas. Objectively deploy cross-media
                                        leadership skills through customer directed sources.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="faq-accordion-2" class="py-4">
                        <h4>Payments Query</h4>
                        <div class="accordion faq-accordion my-5" id="accordionExample-3">
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-3-2">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-3-2" aria-expanded="false">
                                        Can you show me an example?
                                    </button>
                                </h5>
                                <div id="collapse-3-2" class="accordion-collapse collapse" aria-labelledby="faq-3-2"
                                    data-bs-parent="#accordionExample-3">
                                    <div class="accordion-body">
                                        Continually innovate technically sound internal or "organic" sources
                                        with market positioning content. Completely drive optimal intellectual
                                        capital vis-a-vis global human capital. Intrinsicly administrate robust
                                        materials and bleeding-edge resources. Dynamically envisioneer
                                        next-generation markets vis-a-vis market-driven applications
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-3-3">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-3-3" aria-expanded="false">
                                        How do I processing I need?
                                    </button>
                                </h5>
                                <div id="collapse-3-3" class="accordion-collapse collapse" aria-labelledby="faq-3-3"
                                    data-bs-parent="#accordionExample-3">
                                    <div class="accordion-body">
                                        Phosfluorescently deliver cooperative testing procedures after
                                        integrated communities. Dramatically simplify resource-leveling models
                                        with unique outsourcing. Professionally simplify covalent partnerships
                                        whereas market positioning best practices. Collaboratively utilize
                                        magnetic technology for robust technology.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-3-4">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-3-4" aria-expanded="false">
                                        What happens if I go over my limit?
                                    </button>
                                </h5>
                                <div id="collapse-3-4" class="accordion-collapse collapse" aria-labelledby="faq-3-4"
                                    data-bs-parent="#accordionExample-3">
                                    <div class="accordion-body">
                                        Credibly facilitate leveraged process improvements for equity invested
                                        infrastructures. Continually mesh top-line human capital with
                                        backward-compatible outsourcing. Rapidiously coordinate intuitive
                                        deliverables rather than parallel metrics. Interactively monetize
                                        customer directed convergence and parallel sources. Enthusiastically
                                        architect client-centric e-services whereas granular e-commerce.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-3-5">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-3-5" aria-expanded="false">
                                        How do I calculate how much processing I need?
                                    </button>
                                </h5>
                                <div id="collapse-3-5" class="accordion-collapse collapse" aria-labelledby="faq-3-5"
                                    data-bs-parent="#accordionExample-3">
                                    <div class="accordion-body">
                                        Distinctively enable premier potentialities through market positioning
                                        models. Distinctively extend unique infomediaries without
                                        enterprise-wide ideas. Objectively deploy multifunctional catalysts for
                                        change for installed base content. Seamlessly create go forward
                                        convergence through quality schemas. Objectively deploy cross-media
                                        leadership skills through customer directed sources.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="faq-accordion-3" class="py-4">
                        <h4>Setup or Installment</h4>
                        <div class="accordion faq-accordion my-5" id="accordionExample-4">
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-4-4">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-4-4" aria-expanded="false">
                                        What happens if I go over my limit?
                                    </button>
                                </h5>
                                <div id="collapse-4-4" class="accordion-collapse collapse" aria-labelledby="faq-4-4"
                                    data-bs-parent="#accordionExample-4">
                                    <div class="accordion-body">
                                        Credibly facilitate leveraged process improvements for equity invested
                                        infrastructures. Continually mesh top-line human capital with
                                        backward-compatible outsourcing. Rapidiously coordinate intuitive
                                        deliverables rather than parallel metrics. Interactively monetize
                                        customer directed convergence and parallel sources. Enthusiastically
                                        architect client-centric e-services whereas granular e-commerce.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-4-5">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-4-5" aria-expanded="false">
                                        How do I calculate how much processing I need?
                                    </button>
                                </h5>
                                <div id="collapse-4-5" class="accordion-collapse collapse" aria-labelledby="faq-4-5"
                                    data-bs-parent="#accordionExample-4">
                                    <div class="accordion-body">
                                        Distinctively enable premier potentialities through market positioning
                                        models. Distinctively extend unique infomediaries without
                                        enterprise-wide ideas. Objectively deploy multifunctional catalysts for
                                        change for installed base content. Seamlessly create go forward
                                        convergence through quality schemas. Objectively deploy cross-media
                                        leadership skills through customer directed sources.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-4-2">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-4-2" aria-expanded="false">
                                        Can you show me an example?
                                    </button>
                                </h5>
                                <div id="collapse-4-2" class="accordion-collapse collapse" aria-labelledby="faq-4-2"
                                    data-bs-parent="#accordionExample-4">
                                    <div class="accordion-body">
                                        Continually innovate technically sound internal or "organic" sources
                                        with market positioning content. Completely drive optimal intellectual
                                        capital vis-a-vis global human capital. Intrinsicly administrate robust
                                        materials and bleeding-edge resources. Dynamically envisioneer
                                        next-generation markets vis-a-vis market-driven applications
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-4-3">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-4-3" aria-expanded="false">
                                        How do I processing I need?
                                    </button>
                                </h5>
                                <div id="collapse-4-3" class="accordion-collapse collapse" aria-labelledby="faq-4-3"
                                    data-bs-parent="#accordionExample-4">
                                    <div class="accordion-body">
                                        Phosfluorescently deliver cooperative testing procedures after
                                        integrated communities. Dramatically simplify resource-leveling models
                                        with unique outsourcing. Professionally simplify covalent partnerships
                                        whereas market positioning best practices. Collaboratively utilize
                                        magnetic technology for robust technology.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="faq-accordion-4" class="py-4">
                        <h4>Payments Query</h4>
                        <div class="accordion faq-accordion my-5" id="accordionExample-5">
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-5-2">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-5-2" aria-expanded="false">
                                        Can you show me an example?
                                    </button>
                                </h5>
                                <div id="collapse-5-2" class="accordion-collapse collapse" aria-labelledby="faq-5-2"
                                    data-bs-parent="#accordionExample-5">
                                    <div class="accordion-body">
                                        Continually innovate technically sound internal or "organic" sources
                                        with market positioning content. Completely drive optimal intellectual
                                        capital vis-a-vis global human capital. Intrinsicly administrate robust
                                        materials and bleeding-edge resources. Dynamically envisioneer
                                        next-generation markets vis-a-vis market-driven applications
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-5-3">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-5-3" aria-expanded="false">
                                        How do I processing I need?
                                    </button>
                                </h5>
                                <div id="collapse-5-3" class="accordion-collapse collapse" aria-labelledby="faq-5-3"
                                    data-bs-parent="#accordionExample-5">
                                    <div class="accordion-body">
                                        Phosfluorescently deliver cooperative testing procedures after
                                        integrated communities. Dramatically simplify resource-leveling models
                                        with unique outsourcing. Professionally simplify covalent partnerships
                                        whereas market positioning best practices. Collaboratively utilize
                                        magnetic technology for robust technology.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-5-4">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-5-4" aria-expanded="false">
                                        What happens if I go over my limit?
                                    </button>
                                </h5>
                                <div id="collapse-5-4" class="accordion-collapse collapse" aria-labelledby="faq-5-4"
                                    data-bs-parent="#accordionExample-5">
                                    <div class="accordion-body">
                                        Credibly facilitate leveraged process improvements for equity invested
                                        infrastructures. Continually mesh top-line human capital with
                                        backward-compatible outsourcing. Rapidiously coordinate intuitive
                                        deliverables rather than parallel metrics. Interactively monetize
                                        customer directed convergence and parallel sources. Enthusiastically
                                        architect client-centric e-services whereas granular e-commerce.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item border border-2">
                                <h5 class="accordion-header" id="faq-5-5">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse-5-5" aria-expanded="false">
                                        How do I calculate how much processing I need?
                                    </button>
                                </h5>
                                <div id="collapse-5-5" class="accordion-collapse collapse" aria-labelledby="faq-3-5"
                                    data-bs-parent="#accordionExample-5">
                                    <div class="accordion-body">
                                        Distinctively enable premier potentialities through market positioning
                                        models. Distinctively extend unique infomediaries without
                                        enterprise-wide ideas. Objectively deploy multifunctional catalysts for
                                        change for installed base content. Seamlessly create go forward
                                        convergence through quality schemas. Objectively deploy cross-media
                                        leadership skills through customer directed sources.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--faq section end-->